import { parsePrice, centsToDollars, dollarsToCents } from "helpers/price";
import { sizer } from "layout/styles/styled/sizer";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { Row } from "primereact/row";
import { useMemo, useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";

import { IProviderRemainingAmountRow } from "../PaymentDetails";

const StyledDataTable = styled(DataTable)`
  margin-top: ${sizer(6)};
  width: 100%;

  tr {
    background: var(--gray-50);
  }

  span.p-column-title {
    font-weight: var(--font-weight-semibold);
    font-size: var(--fontsize-contents);
    color: var(--color-black-4);
  }
  .p-rowgroup-header {
    display: none;
  }

  td {
    font-weight: var(--font-weight-medium);
    font-size: var(--fontsize-contents);
    color: var(--color-matte-black);
  }
`;

const firstColumnTemplate = (row: IProviderRemainingAmountRow) => {
  return <span className="font-bold">{row.providerName}</span>;
};

const remainingAmountTemplate = (
  row: IProviderRemainingAmountRow,
  onValueChange: any
) => {
  return (
    <InputNumber
      value={centsToDollars(row.totalExpectedTransferAmount)}
      currency="USD"
      locale="en-US"
      mode="currency"
      onValueChange={({ value }) => onValueChange(value, row)}
      minFractionDigits={2}
      maxFractionDigits={2}
      min={0}
      aria-autocomplete="none"
    />
  );
};

const footerGroupForPayments = (
  updatedRemainingTotal: {
    total: number;
    subtotal: number;
  },
  isGFE: boolean
) => {
  return (
    <ColumnGroup>
      {!isGFE && (
        <Row>
          <Column
            footer="Subtotal Amount"
            colSpan={3}
            footerStyle={{ textAlign: "right" }}
          />
          <Column
            footer={parsePrice(centsToDollars(updatedRemainingTotal.subtotal))}
          />
        </Row>
      )}
      <Row>
        <Column
          footer="Remaining Amount"
          colSpan={3}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer={parsePrice(centsToDollars(updatedRemainingTotal.total))}
        />
      </Row>
    </ColumnGroup>
  );
};

export function RemainingAmountEditTable({
  remainingAmountBreakdown,
  differenceByProvider,
  setDifferenceByProvider,
  clearFeePercentage,
  isGFE,
  initialPaymentPlanRemainingAmount,
}: {
  remainingAmountBreakdown: IProviderRemainingAmountRow[];
  differenceByProvider: { [key: string]: number };
  setDifferenceByProvider: any;
  clearFeePercentage: number;
  isGFE: boolean;
  initialPaymentPlanRemainingAmount: number;
}) {
  const { setValue } = useFormContext();
  const handleAmountChange = useCallback(
    (value: number, row: IProviderRemainingAmountRow) => {
      const existingDifference = differenceByProvider[row.providerId] || 0;
      const newDifference =
        dollarsToCents(value) - row.totalExpectedTransferAmount;
      setDifferenceByProvider((prev: any) => ({
        ...prev,
        [row.providerId]: existingDifference + newDifference,
      }));
    },
    [differenceByProvider]
  );

  const updatedRemainingTotal = useMemo(() => {
    const initialRemainingAmount = remainingAmountBreakdown.reduce(
      (sum, provider) => sum + provider.totalExpectedTransferAmount,
      0
    );
    const difference = Object.values(differenceByProvider).reduce(
      (total, amount) => total + amount,
      0
    );
    const subtotal = initialRemainingAmount + difference;
    let total = isGFE ? subtotal : subtotal * (1 + clearFeePercentage / 100);
    if (Math.abs(initialPaymentPlanRemainingAmount - total) <= 1) {
      total = initialPaymentPlanRemainingAmount;
    }
    return { total, subtotal };
  }, [
    remainingAmountBreakdown,
    differenceByProvider,
    isGFE,
    clearFeePercentage,
    initialPaymentPlanRemainingAmount,
  ]);

  const tableData = useMemo(() => {
    return remainingAmountBreakdown.map((provider) => {
      const difference = differenceByProvider[provider.providerId] || 0;
      return {
        ...provider,
        totalExpectedTransferAmount:
          provider.totalExpectedTransferAmount + difference,
      };
    });
  }, [remainingAmountBreakdown, differenceByProvider]);

  useEffect(() => {
    setValue(
      "paymentPlanRemainingAmount",
      centsToDollars(updatedRemainingTotal.total)
    );
  }, [updatedRemainingTotal]);

  const columns = useMemo(() => {
    return [
      {
        field: "providerName",
        header: "Provider",
        body: (row: any) => firstColumnTemplate(row),
      },
      {
        field: "cptCode",
        header: "CPT Code",
        body: (row: any) => {
          return row.cptCode || "N/A";
        },
      },
      { field: "providerPriority", header: "Payment Priority" },
      {
        field: "totalExpectedTransferAmount",
        header: "Remaining to pay",
        body: (row: IProviderRemainingAmountRow) => {
          return remainingAmountTemplate(row, handleAmountChange);
        },
      },
    ];
  }, [handleAmountChange]);

  return (
    <div className="flex col-12 my-3">
      <StyledDataTable
        style={{ marginLeft: "-1rem", marginRight: "-1rem" }}
        value={tableData}
        emptyMessage="No Remaining Amounts Breakdown"
        footerColumnGroup={footerGroupForPayments(updatedRemainingTotal, isGFE)}
      >
        {columns.map((col: any) => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            body={col?.body}
          />
        ))}
      </StyledDataTable>
    </div>
  );
}
