import { LoadingSpinner } from "components/LoadingSpinner";
import {
  orderTypeConstants,
  ED_ORDER_TYPE_EXPIRATION_PERIOD,
} from "constants/order";
import { centsToDollars } from "helpers/price";
import usePrice from "hooks/usePrice";
import { useQuery } from "hooks/useQuery";
import { isEmpty } from "lodash";
import { DEFAULT_EXPIRATION_DAYS } from "pages/OrderOverview/utils/constants";
import { Button } from "primereact/button";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import { useLoginWithOrderIdAndDOBMutation } from "store/queries/auth";
import {
  useGetOrderOverviewQuery,
  useGetPublicOrderQuery,
} from "store/queries/order";
import { onPublicTokenChange } from "store/slices/auth";
import { onChangeAccountInfo } from "store/slices/order";
import { IOrder } from "types/Order/Order";
import { IProvider } from "types/Provider/Provider";

import {
  StyledBreadcrumbContainer,
  StyledBreadCrumb,
} from "../Overview/styled";
import { PaymentSuccessFull } from "../PaymentSection/PaymentSuccessFull/PaymentSuccessFull";
import { PayNowSection } from "../PayNowSection";

export function PayNow() {
  const { id: externalId } = useParams();
  const query = useQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getClearPriceByOrderType } = usePrice();
  const [login] = useLoginWithOrderIdAndDOBMutation();
  const internalUser = query.get("internalUser");

  const [isPublicTokenReady, setIsPublicTokenReady] = useState(false);
  const [showPaymentSection, setShowPaymentSection] = useState({
    paymentSection: false,
    paymentPlan: false,
    paymentSuccess: false,
    receiptAvailable: true,
  });

  // get the order overview for patient date of birth
  const {
    data: overviewData,
    isLoading: getOrderOverviewIsLoading,
    isError: getOrderOverviewError,
  } = useGetOrderOverviewQuery(externalId, { skip: !externalId });

  const {
    data: orderData,
    isLoading: getPublicOrderIsLoading,
    isError: getPublicOrderError,
  } = useGetPublicOrderQuery(externalId, {
    skip: !isPublicTokenReady,
  });

  const orderInfo = useMemo(() => {
    if (isEmpty(orderData)) return {} as IOrder;

    const accountInfo = orderData.data.account;

    if (accountInfo) {
      dispatch(onChangeAccountInfo({ accountInfo }));
    }

    return orderData.data;
  }, [orderData]);

  const publicContact = () => {
    const currentProvider = orderInfo.account
      .providers[0] as unknown as IProvider;

    const isVisible =
      currentProvider.publicContactName &&
      currentProvider.publicContactRole &&
      currentProvider.publicContactPhone;

    return {
      isVisible,
      name: currentProvider.publicContactName,
      role: currentProvider.publicContactRole,
      phone: currentProvider.publicContactPhone,
    };
  };

  useEffect(() => {
    if (isEmpty(overviewData)) return;
    login({
      orderId: externalId || "",
      dateOfBirth: overviewData.data.patient.dob,
    })
      .unwrap()
      .then(({ token }: { token: string }) => {
        dispatch(onPublicTokenChange({ token }));
        setIsPublicTokenReady(true);
      })
      .catch((error) => {
        setIsPublicTokenReady(false);
        console.error(
          `Authentication failed for order ${externalId} with date of birth ${overviewData.data.patient.dob}.`,
          error
        );
      });
  }, [overviewData]);

  const prices = getClearPriceByOrderType({ order: orderInfo });
  const activePaymentPlan = orderInfo.paymentPlan?.find(
    (paymentPlan) => !!paymentPlan.active
  );

  const fullPrice = centsToDollars(prices.total);
  const remainingPrice = centsToDollars(
    activePaymentPlan?.remainingAmountInCents || 0
  );
  const totalSaving = centsToDollars(prices.totalSaving);

  const getExpirationDays = () => {
    if (isEmpty(orderData)) return undefined;

    if (getPublicOrderIsLoading) return DEFAULT_EXPIRATION_DAYS;

    if (orderInfo.orderType === orderTypeConstants.ED) {
      return ED_ORDER_TYPE_EXPIRATION_PERIOD;
    }

    return (orderInfo.account.providers[0] as unknown as IProvider)
      .expirationDays;
  };

  const expirationDays = getExpirationDays();
  const breadcrumbItems = useMemo(() => {
    const itemsBase = {
      label: "Order",
      template: (
        <Button
          onClick={() => {
            navigate(`/orders/${orderInfo.id}`);
          }}
          className="p-button-text"
        >
          Order
        </Button>
      ),
    };
    const checkoutItem = {
      label: "Checkout",
      template: <span className="active">Checkout</span>,
    };
    return [itemsBase, checkoutItem];
  }, [orderInfo]);

  // We can't use the useLoading hook because the clientSecret is required to load the payment for now.
  if (
    !isPublicTokenReady ||
    getPublicOrderIsLoading ||
    getOrderOverviewIsLoading
  ) {
    return <LoadingSpinner />;
  }

  if (getPublicOrderError || getOrderOverviewError) {
    return <Navigate to="/not-found" />;
  }

  if (showPaymentSection.paymentSuccess && externalId && orderInfo) {
    return (
      <PaymentSuccessFull
        publicContact={publicContact}
        orderExternalId={externalId}
        receiptAvailable={showPaymentSection.receiptAvailable}
      />
    );
  }

  return (
    <div className="flex flex-column w-full align-items-center">
      {internalUser && (
        <StyledBreadcrumbContainer className="p-3">
          <StyledBreadCrumb model={breadcrumbItems} />
        </StyledBreadcrumbContainer>
      )}
      <PayNowSection
        {...{
          expirationDays,
          orderInfo,
          showPaymentSection,
          setShowPaymentSection,
          clearPrice: remainingPrice,
          totalSaving,
          fullPrice,
          payRemainingPrice: true,
        }}
      />
    </div>
  );
}
