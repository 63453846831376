import { Document, Page, Text, Image, View } from "@react-pdf/renderer";
import { parseDate } from "helpers/date";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { Pricing } from "types/CreateOrder";
import {
  IDefaultProcedure,
  IPatientResponsibilityProcedure,
} from "types/Order/Order";
import { SpecialtyList } from "types/Provider/Provider";

import { cellphoneImage } from "./assets/cellphoneImage";
import { detailBgImage } from "./assets/detailBgImage";
import { ChargeDescriptionClearEstimate } from "./partials/ChargeDescription";
import { ChargeDescriptionV3 } from "./partials/ChargeDescriptionV3";
import { EnglishInformation } from "./partials/EnglishInformation";
import { Footer } from "./partials/Footer";
import { Header } from "./partials/Header";
import { SpanishInformation } from "./partials/SpanishInformation";
import { V3Disclaimer } from "./partials/V3Disclaimer";
import { styles } from "./styles";

export type Props = {
  name: string;
  facilityLogo: string;
  fullAddress?: string;
  phoneNumber?: string;
  serviceDetails: any;
  dateOfBirth: string;
  payerName: string;
  serviceDate: string;
  facilityName?: string;
  gfeQuote: Pricing | undefined;
  clearPriceToPay: number;
  isGFE: boolean;
  insuranceAmounts: any;
  providerFee: number | undefined;
  facilityFee: number;
  clearDiscount: number;
  gfeNoDiscountPdf?: boolean;
  gfeDisclaimer?: {
    disclaimerEnglish: { title?: string; description?: string };
    disclaimerSpanish: { title?: string; description?: string };
  };
  accountNumber?: string;
  note?: string;
  isVersion3Pdf?: boolean;
  hoursEstimated?: number | null;
  fullProviderBreakdown?: any;
};

export function ClearOrderDocument({
  name,
  facilityLogo,
  fullAddress,
  phoneNumber,
  serviceDetails,
  dateOfBirth,
  payerName,
  serviceDate,
  facilityName,
  gfeQuote,
  clearPriceToPay,
  isGFE,
  insuranceAmounts,
  providerFee,
  facilityFee,
  clearDiscount,
  gfeNoDiscountPdf,
  gfeDisclaimer,
  accountNumber,
  note,
  isVersion3Pdf,
  hoursEstimated,
  fullProviderBreakdown,
}: Props) {
  type UniqueProcedureList =
    | IDefaultProcedure
    | IPatientResponsibilityProcedure;

  const uniqueProcedureList = useMemo(() => {
    if (isEmpty(serviceDetails)) return [];

    const { procedures } = serviceDetails;

    const uniqueArray = [] as unknown as UniqueProcedureList[];
    const seenValues = new Set();

    procedures?.forEach((item: any) => {
      const valueToCheck = item.code;

      if (!seenValues.has(valueToCheck)) {
        seenValues.add(valueToCheck);
        uniqueArray.push(item);
      }
    });

    return uniqueArray;
  }, [serviceDetails]);

  const showPromptPayDiscount = useMemo(() => {
    if (isGFE && gfeNoDiscountPdf) return false;
    return true;
  }, [isGFE, gfeNoDiscountPdf]);

  const exparel = useMemo(() => {
    const exparelProceduresAmount = serviceDetails.procedures
      .filter((p: any) => p?.code === "Exparel")
      .reduce((acc: number, current: any) => {
        return acc + (current?.amountInCents || 0);
      }, 0);
    return exparelProceduresAmount || 0;
  }, [serviceDetails]);

  const scd = useMemo(() => {
    const scdProceduresAmount = serviceDetails.procedures
      .filter((p: any) => p?.code === "SCD")
      .reduce((acc: number, current: any) => {
        return acc + (current?.amountInCents || 0);
      }, 0);
    return scdProceduresAmount || 0;
  }, [serviceDetails]);

  const surgeonFees = useMemo(() => {
    const sumOfSurgeonFees = fullProviderBreakdown
      ?.filter((p: any) => p?.specialty === SpecialtyList.PRIMARY_PROVIDER)
      .reduce((acc: number, current: any) => {
        return acc + (current?.clearDiscountedPrice || 0);
      }, 0);
    return sumOfSurgeonFees || 0;
  }, [fullProviderBreakdown]);

  const anesthesiaFees = useMemo(() => {
    const sumOfAnesthesiaFees = fullProviderBreakdown
      ?.filter((p: any) => p?.specialty === SpecialtyList.ANESTHESIOLOGIST)
      .reduce((acc: number, current: any) => {
        return acc + (current?.clearDiscountedPrice || 0);
      }, 0);
    return sumOfAnesthesiaFees || 0;
  }, [fullProviderBreakdown]);

  const facilityFees = useMemo(() => {
    const sumOfFacilityFees = fullProviderBreakdown
      ?.filter((p: any) => p?.specialty === SpecialtyList.FACILITY)
      .reduce((acc: number, current: any) => {
        return acc + (current?.clearDiscountedPrice || 0);
      }, 0);
    return sumOfFacilityFees - scd - exparel;
  }, [fullProviderBreakdown, scd, exparel]);

  return (
    <Document>
      <Page size="A4" style={{ ...styles.page, position: "relative" }}>
        <Header
          facilityLogo={facilityLogo}
          fullAddress={fullAddress}
          phoneNumber={phoneNumber}
        />
        {/* CONTENT */}
        <View style={styles.content}>
          <View style={{ ...styles.section, marginTop: 12 }}>
            <Text style={styles.title}>Good Faith Estimate</Text>
          </View>
          <View style={{ ...styles.section, marginTop: 0 }}>
            <Text style={{ ...styles.bodyText, lineHeight: 1.2 }}>
              This estimate uses procedure information provided by your
              physician as well as the benefits available from your insurance
              company (if applicable). This is not a guarantee of the final
              amount due. Hospital care is tailored to the specific needs of
              each patient. Therefore, the final amount owed may vary based on
              circumstances involved in your actual services.
            </Text>
            {!isVersion3Pdf && (
              <Text style={{ ...styles.bodyText, lineHeight: 1.2 }}>
                Esta estimación utiliza la información del procedimiento
                proporcionada por su médico, así como los beneficios disponibles
                de su compañía de seguros (si corresponde). Esto no es una
                garantía del monto final adeudado. La atención hospitalaria se
                adapta a las necesidades específicas de cada paciente. Por lo
                tanto, el monto final adeudado puede variar según las
                circunstancias involucradas en sus servicios reales.
              </Text>
            )}
          </View>
          <View style={{ ...styles.section, marginTop: 0, marginBottom: 0 }}>
            <View
              style={{
                display: "flex",
                marginBottom: "8px",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={{ ...styles.listTitle, width: 100 }}>Patient</Text>
              <Text
                style={styles.smallText}
              >{`${name}, Date of Birth: ${parseDate(dateOfBirth)}`}</Text>
            </View>
            {!isEmpty(payerName) && (
              <View
                style={{
                  display: "flex",
                  marginBottom: "8px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ ...styles.listTitle, width: 100 }}>Payer</Text>
                <Text style={styles.smallText}>{`${payerName}`}</Text>
              </View>
            )}
            <View
              style={{
                display: "flex",
                marginBottom: "8px",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={{ ...styles.listTitle, width: 100 }}>
                Service Date
              </Text>
              <Text style={styles.smallText}>{`${parseDate(
                serviceDate
              )}`}</Text>
            </View>
            {isGFE && (
              <View
                style={{
                  display: "flex",
                  marginBottom: "8px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ ...styles.listTitle, width: 100 }}>
                  Account number
                </Text>
                <Text style={styles.smallText}>{accountNumber}</Text>
              </View>
            )}
          </View>
          <View style={{ ...styles.section, marginBottom: 4, marginTop: 0 }}>
            <View style={styles.list}>
              <Text style={{ ...styles.listTitle, marginBottom: 4 }}>
                Services Provided
              </Text>

              {uniqueProcedureList.map((currentProcedure) => (
                <Text style={styles.itemList} key={currentProcedure.code}>
                  {currentProcedure.cptDescription} - {currentProcedure.code}
                </Text>
              ))}
            </View>
          </View>
          {isVersion3Pdf ? (
            <ChargeDescriptionV3
              surgeonFees={surgeonFees}
              anesthesiaFees={anesthesiaFees}
              facilityFees={facilityFees}
              scd={scd}
              exparel={exparel}
              clearPriceToPay={clearPriceToPay}
            />
          ) : (
            <ChargeDescriptionClearEstimate
              gfeQuote={gfeQuote}
              clearPriceToPay={clearPriceToPay}
              isGFE={isGFE}
              insuranceAmounts={insuranceAmounts}
              facilityFee={facilityFee}
              providerFee={providerFee}
              clearDiscount={clearDiscount.toString()}
              showPromptPayDiscount={showPromptPayDiscount}
              payerName={payerName}
            />
          )}
          {showPromptPayDiscount && !isVersion3Pdf && (
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    zIndex: -1,
                    width: "100%",
                    position: "absolute",
                    marginTop: 60,
                  }}
                >
                  <Image src={detailBgImage} />
                </View>
                <View
                  style={{
                    flex: 2,
                    textAlign: "left",
                    marginTop: 50,
                    paddingTop: "20px",
                    paddingBottom: "30px",
                    paddingLeft: "60px",
                    paddingRight: "60px",
                    marginRight: 50,
                    marginLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      ...styles.bodyText,
                      marginBottom: 0,
                      lineHeight: "1.6px",
                    }}
                  >
                    To qualify for the{" "}
                    <Text style={{ fontFamily: "Helvetica-Bold" }}>
                      Prompt Pay Discount
                    </Text>{" "}
                    {`you need to pay before ${parseDate(
                      serviceDate,
                      true
                    )} using the link provided to you via text through our Clear Health Partner.`}
                  </Text>
                  <Text style={{ ...styles.bodyText, lineHeight: "1.6px" }}>
                    For more information, please contact Clear Health at
                    1-888-918-2522.
                  </Text>
                  <Text style={styles.bodyText}>
                    {`For questions regarding your estimate please call 1-${phoneNumber}`}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-end",
                    paddingRight: 20,
                  }}
                >
                  <Image
                    style={{ height: 270, width: 200 }}
                    src={cellphoneImage}
                  />
                </View>
              </View>
            </View>
          )}
          {!showPromptPayDiscount && !isVersion3Pdf && (
            <View style={styles.content}>
              <View style={{ ...styles.section, marginTop: 20 }}>
                <Text
                  style={{
                    ...styles.bodyText,
                    lineHeight: 1.2,
                    textAlign: "justify",
                  }}
                >
                  {note || ""}
                </Text>
              </View>
            </View>
          )}
          {isVersion3Pdf && (
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "justify",
              }}
            >
              <View style={{ ...styles.section, marginTop: 20 }}>
                <Text
                  style={{
                    ...styles.bodyText,
                    lineHeight: 1.2,
                    textAlign: "justify",
                  }}
                >
                  {note ? (
                    <>
                      <Text
                        style={{
                          ...styles.bodyTextBold,
                          lineHeight: 1.2,
                          textAlign: "justify",
                          fontFamily: "Helvetica-Bold",
                        }}
                      >
                        Note added from office:
                      </Text>{" "}
                      {note}{" "}
                    </>
                  ) : (
                    ""
                  )}
                </Text>
              </View>
            </View>
          )}
          {isVersion3Pdf && <V3Disclaimer hoursEstimated={hoursEstimated} />}
        </View>
        {(!showPromptPayDiscount || isVersion3Pdf) && (
          <View
            style={{
              marginTop: 40,
              borderTop: "1px solid black",
              width: "50%",
              alignSelf: "center",
              position: "absolute",
              bottom: 40,
            }}
          >
            <Text
              style={{
                ...styles.bodyText,
                textAlign: "center",
                marginTop: 4,
              }}
            >
              Sign here
            </Text>
          </View>
        )}
        {/* END OF CONTENT */}
        <Footer facilityName={facilityName} />
      </Page>
      {!isVersion3Pdf && (
        <EnglishInformation
          facilityLogo={facilityLogo}
          fullAddress={fullAddress || ""}
          phoneNumber={phoneNumber || ""}
          facilityName={facilityName || ""}
          isGFE={isGFE}
          gfeDisclaimer={gfeDisclaimer?.disclaimerEnglish}
        />
      )}
      {!isVersion3Pdf && (
        <SpanishInformation
          facilityLogo={facilityLogo}
          fullAddress={fullAddress || ""}
          phoneNumber={phoneNumber || ""}
          facilityName={facilityName || ""}
          isGFE={isGFE}
          gfeDisclaimer={gfeDisclaimer?.disclaimerSpanish}
        />
      )}
    </Document>
  );
}
