import { validationConstants } from "constants/formValidation";
import { orderStatusConstants } from "constants/order";
import useFormValidation from "hooks/useFormValidation";
import { sizer } from "layout/styles/styled/sizer";
import { getOrderStatus } from "pages/CreateOrder/utils/helpers";
import { Card } from "primereact/card";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
import { useEffect } from "react";
import { Controller, useFormContext, FieldValues } from "react-hook-form";
import styled from "styled-components";
import { IAccount } from "types/Account/Account";

import {
  redoOrderSMSTemplate,
  redoOrderNoBalanceDueSMSTemplate,
} from "./helpers";

const StyledCard = styled(Card)`
  margin-bottom: ${sizer(4)};
`;

export interface ICommunicationFields {
  textMessage: string;
}

const options = [
  {
    label: "Send Default Text",
    value: true,
  },
  {
    label: "Send Custom Text",
    value: false,
  },
];

export function Communication({
  account,
  redo,
}: {
  account: IAccount;
  redo?: boolean;
}) {
  const {
    control,
    trigger,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<FieldValues>();
  const { handleOnBlurField, getFormErrorMessage } = useFormValidation();

  useEffect(() => {
    setValue("sendDefaultText", true);
  }, []);
  const orderTypefield = watch("orderType");
  const defaultText = watch("sendDefaultText");
  const preferredContactMethod = watch("preferredContactMethod");
  const orderTypeField = watch("orderType");
  const amountField = watch("amount");
  const totalAmountField = watch("totalAmount");

  useEffect(() => {
    if (redo) {
      const orderStatus = getOrderStatus({
        amount: amountField,
        totalAmount: totalAmountField,
        orderType: orderTypeField,
      });
      const messageTemaple =
        orderStatus === orderStatusConstants.NO_BALANCE_DUE
          ? redoOrderNoBalanceDueSMSTemplate
          : redoOrderSMSTemplate;
      setValue("customText", messageTemaple);
      return;
    }
    const communicationTemplatesForOrderType =
      account.communicationTemplates.find((template) => {
        return template.orderType === orderTypefield;
      });
    const textMessage = communicationTemplatesForOrderType?.smsTemplate;
    setValue("customText", textMessage);
  }, [
    orderTypefield,
    account,
    redo,
    amountField,
    totalAmountField,
    orderTypeField,
  ]);

  useEffect(() => {
    if (preferredContactMethod === "Email") {
      setValue("sendDefaultText", true);
    }
  }, [preferredContactMethod]);

  const optionTemplate = (option: any) => {
    return option.label;
  };

  if (!preferredContactMethod || preferredContactMethod === "Email") {
    return null;
  }
  return (
    <StyledCard data-testid="communication" title="Text Message">
      <Controller
        name="sendDefaultText"
        control={control}
        render={({ field: { onChange, value } }) => (
          <SelectButton
            value={value}
            id="sendDefaultText"
            data-testid="sendDefaultText"
            onChange={onChange}
            options={options}
            itemTemplate={optionTemplate}
          />
        )}
      />
      {!defaultText && (
        <Controller
          name="customText"
          control={control}
          rules={{
            required: validationConstants.TEXT_MESSAGE_FORM.CUSTOM_TEXT,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <InputTextarea
                id="customText"
                autoComplete="off"
                className="mt-3 w-full"
                value={value || ""}
                rows={3}
                aria-labelledby="customText"
                onBlur={() => {
                  handleOnBlurField({
                    onBlur,
                    field: `customText`,
                    trigger,
                  });
                }}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
              />

              {getFormErrorMessage("customText", errors)}
            </>
          )}
        />
      )}
    </StyledCard>
  );
}
