export const convertToPercent = (value: string) => {
  console.log("value", value, value.includes("%"));
  if (typeof value === "string" && value.includes(".")) {
    const number = parseFloat(value);
    if (!Number.isNaN(number)) {
      return value.includes("%") ? `${number * 100}` : `${number * 100}%`;
    }
  }
  return value; // Return the original value if no conversion is needed
};
