import styled from "styled-components";

export const ContentContainer = styled.div`
  margin-top: 80px;
  @media (max-width: 720px) {
    margin-top: 62px !important;
  }
`;

export const ImageContainer = styled.div`
  height: 80px;

  @media (max-width: 720px) {
    height: 62px;
  }
  @media (min-width: 1024px) {
    padding-left: 96px !important;
    padding-right: 96px !important;
  }
`;

export const StyledImage = styled.img`
  height: 42px;

  @media (max-width: 720px) {
    height: 30px;
  }
`;
