export const buildURLforPatients = ({
  page,
  pageSize,
  firstName,
  lastName,
  phoneNumber,
  accountName,
  accountNumber,
  orderId,
  accountIds,
}: {
  page?: number;
  pageSize?: number;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  accountName?: string;
  accountNumber?: string;
  orderId?: string;
  accountIds?: string[] | [];
}) => {
  const baseUrl = `/patient/all`;
  const pagination =
    page !== undefined && pageSize !== undefined
      ? `page=${page}&pageSize=${pageSize}`
      : null;
  const filtrationParams = [
    !firstName || !firstName.length ? null : `firstName=${firstName}`,
    !lastName || !lastName.length ? null : `lastName=${lastName}`,
    !phoneNumber || !phoneNumber.length ? null : `phoneNumber=${phoneNumber}`,
    !accountName || !accountName.length ? null : `accountName=${accountName}`,
    !accountNumber || !accountNumber.length
      ? null
      : `accountNumber=${accountNumber}`,
    !orderId || !orderId.length ? null : `orderId=${orderId}`,
    !accountIds ? null : `accountIds=${accountIds.join(",")}`,
  ];
  const queryParams = [pagination, ...filtrationParams]
    .filter(Boolean)
    .join("&");
  const finalUrl = `${baseUrl}${queryParams ? `?${queryParams}` : ""}`;
  return finalUrl;
};
