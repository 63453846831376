import {
  addSelectedProcedures,
  addAutoCompleteValue,
  removeSelectedProcedures,
} from "store/slices/serviceDetails";
import {
  IDefaultProcedure,
  IPatientResponsibilityProcedure,
} from "types/Order/Order";
import { IProcedure } from "types/Procedure";
import { SpecialtyList } from "types/Provider/Provider";
import { IProviderProcedure } from "types/ProviderProcedure";

import { ProcedureRow } from "../../../../types/CreateOrder";
import { ISelectedProcedure } from "./types";

export const getProcedureId = (
  procedures: IProcedure[],
  selectedProcedure: ISelectedProcedure
) =>
  procedures.find(
    (procedure: { cptCode: string }) =>
      procedure?.cptCode === selectedProcedure.code
  )?.id;

export const getProviderProceduresInfo = (
  providerProcedures: IProviderProcedure[],
  procedureId: number | undefined
) =>
  providerProcedures.filter(
    (providerProcedure: { procedureId: number | undefined }) =>
      providerProcedure?.procedureId === procedureId
  );

export const isSpecialtyAlreadyAdded = (
  internalRows: ProcedureRow[],
  index: number,
  procedureId: number | undefined,
  providerSpecialty: SpecialtyList
) =>
  internalRows.find(
    (currentRow: {
      providerDropdown: { specialty: SpecialtyList }[];
      internalId: number;
      procedureId: number;
    }) =>
      currentRow?.providerDropdown &&
      currentRow.internalId === index &&
      currentRow.procedureId === procedureId &&
      currentRow.providerDropdown[0]?.specialty === providerSpecialty
  );

export const getProcedureProvidersWithSameSpecialty = (
  providerProceduresInfo: any[],
  providerSpecialty: SpecialtyList
) =>
  providerProceduresInfo.filter(
    (providerProcedure: { provider: { specialty: SpecialtyList } }) =>
      providerProcedure?.provider.specialty === providerSpecialty
  );

export const addProviderProcedureQuantity = (
  value: number | null,
  groupByCptCode: ProcedureRow[]
) => {
  const newRowsToArray: ProcedureRow[] = [];

  if (value && value > 0) {
    for (let i = 0; i < value; i += 1) {
      groupByCptCode.forEach((item) => {
        newRowsToArray.push({ ...item });
      });
    }
  }
  return newRowsToArray;
};

export const matchProcedureWithDraftOrderProcedure = (
  selectedDraftOrderServiceDetailsProcedures: IDefaultProcedure[],
  providerProcedures: IProviderProcedure[]
) => {
  return selectedDraftOrderServiceDetailsProcedures.flatMap((draftProcedure) =>
    providerProcedures.filter(
      (providerProcedure) =>
        draftProcedure.procedureId === providerProcedure.procedureId &&
        draftProcedure.providerId.toString() === providerProcedure.providerId
    )
  );
};

export const addQuantityToRow = (
  procedures: IDefaultProcedure[] | IPatientResponsibilityProcedure[]
) => {
  const parsedProcedures = [...procedures] as IDefaultProcedure[];

  const groupedByProcedureId: {
    [procedureId: number]: { [cptCode: string]: number };
  } = {};
  parsedProcedures.forEach(({ procedureId, code }) => {
    if (!groupedByProcedureId[procedureId]) {
      groupedByProcedureId[procedureId] = { [code]: 1 };
    } else if (groupedByProcedureId[procedureId][code]) {
      groupedByProcedureId[procedureId][code] += 1;
    } else {
      groupedByProcedureId[procedureId][code] = 1;
    }
  });

  const groupingAndCount: { [key: string]: any } = {};

  procedures.forEach((procedure: any) => {
    const key = `${procedure.code}-${procedure.procedureId}-${procedure.providerProcedureId}`;
    if (!groupingAndCount[key]) {
      groupingAndCount[key] = {
        ...procedure,
        quantity: 1,
      };
    } else {
      groupingAndCount[key].quantity += 1;
    }
  });

  return Object.values(groupingAndCount);
};

export const getQuantitiesByUniqueKey = (
  procedures: IDefaultProcedure[]
): { [key: string]: number } => {
  const quantitiesByUniqueKey: { [key: string]: number } = {};

  procedures.forEach(({ code, procedureId, providerProcedureId }) => {
    const key = `${code}-${procedureId}-${providerProcedureId}`;
    if (quantitiesByUniqueKey[key]) {
      quantitiesByUniqueKey[key] += 1;
    } else {
      quantitiesByUniqueKey[key] = 1;
    }
  });

  return quantitiesByUniqueKey;
};

interface IProcedureCount {
  [key: string]: {
    count: number;
    code: number;
    description: string;
  };
}

export function getProviderProceduresSelected(procedures: IDefaultProcedure[]) {
  const procedureCount: IProcedureCount = {};
  const result: string[] = [];

  procedures.forEach((procedure) => {
    const key = procedure.procedureId;
    if (!procedureCount[key]) {
      procedureCount[key] = {
        count: 1,
        code: procedure.code,
        description: procedure.cptDescription,
      };
    } else {
      procedureCount[key].count += 1;
    }
  });

  Object.values(procedureCount).forEach(({ count, code, description }) => {
    if (count === 1) {
      result.push(`${code} - ${description}`);
    } else {
      // If a procedure repeats, push its formatted string for each occurrence
      for (let i = 0; i < count; i += 1) {
        result.push(`${code} - ${description}`);
      }
    }
  });

  return result;
}

export const uniqueByField = (obj: { [key: string]: any }, field: string) => {
  const uniqueObj = Object.values(obj).reduce((acc, item) => {
    if (!acc[item[field]]) {
      acc[item[field]] = item;
    }
    return acc;
  }, {} as { [key: string]: any });

  return uniqueObj;
};

export const isValidPrice = (priceInCents: number) =>
  !Number.isNaN(priceInCents);

export const updateProcedureQuantity = (
  previousQuantity: number,
  selectedProcedures: string[],
  procedure: string,
  newValue: number,
  dispatch: any
) => {
  if (newValue > previousQuantity) {
    for (let i = previousQuantity; i < newValue; i += 1) {
      selectedProcedures.push(procedure);
      dispatch(addSelectedProcedures(procedure));
      dispatch(addAutoCompleteValue(procedure));
    }
  } else if (newValue < previousQuantity) {
    for (let i = previousQuantity; i > newValue; i -= 1) {
      const index = selectedProcedures.indexOf(procedure);
      if (index > -1) {
        selectedProcedures.splice(index, 1);
        dispatch(removeSelectedProcedures(procedure));
      }
    }
  }
};

export const getPreviousQuantity = (
  selectedProcedures: any,
  value: string
): number => {
  let theCount = 0;
  const uniqueProcedures: any[] = [];

  selectedProcedures.forEach((procedure: any) => {
    const key = `${procedure.cptCode} - ${procedure.description}`;
    const isUnique = uniqueProcedures.find(
      (u) => u.internalId === procedure.internalId
    );
    if (key === value && !isUnique) {
      theCount += 1;
      uniqueProcedures.push(procedure);
    }
  });
  return theCount;
};

export const getNextInternalId = (rows: ProcedureRow[]): number => {
  if (rows.length === 0) return 1; // If no rows, start with 1

  const maxInternalID = rows.reduce((max, row) => {
    return row.internalId > max ? row.internalId : max;
  }, rows[0].internalId);

  return maxInternalID + 1;
};

export const getCptQuantities = (data: ProcedureRow[]) => {
  // Group all items by their cptCode.
  const groupedByCpt = {} as any;
  data.forEach((item) => {
    const cpt = item.cptCode;
    if (!groupedByCpt[cpt]) {
      groupedByCpt[cpt] = [];
    }
    groupedByCpt[cpt].push(item);
  });

  // For each CPT code group:
  //    - figure out how many unique combos of (cptCode, providerProcedureId, amount) exist
  //    - divide the total items in that group by the number of unique combos
  //      => "quantity"

  const result = {} as { [key: string]: number };

  Object.entries(groupedByCpt).forEach(([cpt, items]: any) => {
    // Create a set to track unique combos
    const uniqueCombos = new Set();
    items.forEach((item: any) => {
      const combo = JSON.stringify({
        cptCode: item.cptCode,
        providerProcedureId: item.providerProcedureId,
        amount: item.amount,
      });
      uniqueCombos.add(combo);
    });

    const repeatedTimes = items.length / uniqueCombos.size;
    // Store the "quantity" for this CPT code
    result[cpt] = repeatedTimes;
  });

  return result;
};

export const replicateByCptQuantity = (
  selectedProcedures: any,
  cptQuantities: { [key: string]: number }
) => {
  return selectedProcedures.flatMap((procedure: any) => {
    const { code } = procedure;
    const quantity = cptQuantities[code] || 1;
    return Array.from({ length: quantity }, () => ({ ...procedure }));
  });
};
