import { orderTypeConstants } from "constants/order";
import { distributionLogicValueList } from "constants/paymentPlanConstants";
import { mapPayerNameToCodeForDropdown } from "constants/practiceType";
import { parseDate, parseTime } from "helpers/date";
import { centsToDollars } from "helpers/price";
import { usePayerInformation } from "hooks/usePayerInformation";
import { CLEAR_HEALTH_PAYER_NAME } from "hooks/useProviderProcedure";
import { useMemo, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSearchProviderProcedureMutation } from "store/queries/providerProcedure";
import {
  setAutoCompleteValue,
  setRows,
  setSelectedProcedures,
} from "store/slices/serviceDetails";
import {
  IOrder,
  IGFEServiceDetails,
  IDefaultServiceDetails,
} from "types/Order/Order";

import {
  VisitForm,
  Submit,
  PatientInfoForm,
  OrderType,
  ServiceDetails,
  PayerForm,
  PatientBenefitsForm,
  Communication,
} from "./components";
import { PaymentPlan } from "./components/PaymentPlan/PaymentPlan";
import { RedoHeader } from "./components/RedoHeader";
import {
  buildSelectedProceduresArray,
  buildSelectedProceduresObject,
  buildSelectedProceduresToTable,
} from "./components/ServiceDetails/builders";
import { formatPhoneNumberToMask } from "./utils/mask";

export function RedoOrder({
  order,
  handleSwitchRedoOrder,
}: {
  order: IOrder;
  handleSwitchRedoOrder: () => void;
}) {
  const dispatch = useDispatch();
  const [searchMutation] = useSearchProviderProcedureMutation();
  const [currentProviderProcedures, setCurrentProviderProcedures] =
    useState<any>([]);

  const {
    serviceDetails,
    patient,
    procedures,
    providerProcedures,
    amountInCents,
    accountNumber,
    dateOfService,
    orderType,
    accountId,
    gfeQuote,
    gfeQuoteId,
    id,
    paymentPlan,
    account,
    outreachStopped,
  } = order;

  const { payerNameOptions } = usePayerInformation(accountId);

  const estimatedVisitCost = useMemo(() => {
    if (orderType === orderTypeConstants.GFE) {
      return centsToDollars(
        (serviceDetails as IGFEServiceDetails)
          .patientResponsibilityEstimateAmountInCents || 0
      );
    }
    return centsToDollars(
      (serviceDetails as IDefaultServiceDetails).estimatedVisitCost || 0
    );
  }, [orderType, serviceDetails]);

  const payerName = useMemo(() => {
    if (orderType === orderTypeConstants.GFE) {
      return payerNameOptions.find(
        (aOption: { name: string; value: string }) =>
          aOption.name.toLowerCase() ===
          (serviceDetails as IGFEServiceDetails).payerName.toLowerCase()
      );
    }
    return null;
  }, [orderType, payerNameOptions]);

  const paymentPlanActive = useMemo(
    () => paymentPlan.find((paymentPlan) => paymentPlan.active),
    [paymentPlan]
  );

  const selectedProceduresList = useMemo(() => {
    return serviceDetails.procedures.map(
      (procedure) => `${procedure.code} - ${procedure.cptDescription}`
    );
  }, [serviceDetails]);

  const initializeDefault = async () => {
    const autoCompleteValue = [
      ...new Set(
        serviceDetails.procedures.map(
          (procedure) =>
            `${procedure.code} - ${
              procedures?.find((p) => p.cptCode === procedure.code.toString())
                ?.description
            }`
        )
      ),
    ];
    const selectedProcedureArrayWithDefaultDescriptions =
      buildSelectedProceduresArray({
        procedures: (serviceDetails as IGFEServiceDetails).procedures.map(
          (procedure) => {
            const defaultDescription = procedures?.find(
              (p) => p.cptCode === procedure.code.toString()
            )?.description;
            return {
              code: procedure.code,
              providerProcedureId: procedure.providerProcedureId,
              specialty: procedure.specialty,
              cptDescription: defaultDescription || "",
            };
          }
        ),
      });
    const selectedProcedureArray = buildSelectedProceduresArray({
      procedures: (serviceDetails as IGFEServiceDetails).procedures.map(
        (procedure) => {
          return {
            code: procedure.code,
            providerProcedureId: procedure.providerProcedureId,
            specialty: procedure.specialty,
            cptDescription: procedure.cptDescription,
          };
        }
      ),
    });
    const selectedProceduresObject = buildSelectedProceduresObject(
      selectedProcedureArray
    );
    const rowsToSet = buildSelectedProceduresToTable({
      selectedProceduresObject,
      procedures: procedures || [],
      providerProcedures: providerProcedures || [],
      rows: [],
    });
    const rowsWithoutDuplicateProviders = rowsToSet.map((row: any) => {
      const uniqueProviders = row.providerDropdown.filter(
        (provider: any, index: any, self: any) =>
          index === self.findIndex((p: any) => p.id === provider.id)
      );
      return {
        ...row,
        providerDropdown: uniqueProviders,
      };
    });

    const rowsWithCurrentAmount = rowsWithoutDuplicateProviders.map((row) => {
      const procedure = serviceDetails.procedures.find(
        (p) =>
          p.code === row.cptCode &&
          p.specialty === row.specialty &&
          p.providerId === row.selectedProvider.id &&
          p.providerProcedureId === row.providerProcedureId
      );
      const procedureAmount =
        procedure?.amountInCents !== undefined &&
        procedure?.amountInCents !== null
          ? procedure?.amountInCents
          : row.amount;
      return { ...row, amount: procedureAmount };
    });

    const fetchedProviderProcedures: any[] = await Promise.all(
      selectedProceduresObject.map(async (procedure) => {
        const result = (await searchMutation({
          accountId,
          cptCode: procedure.code,
          payerName:
            orderType === orderTypeConstants.GFE
              ? serviceDetails.payerName
              : CLEAR_HEALTH_PAYER_NAME,
        })) as any;
        return result.data.data.data;
      })
    ).then((results) => results.flat());

    const rowsWithAllAvailableProviders = rowsWithCurrentAmount.map((row) => {
      const providerData = [...row.providerDropdown];
      fetchedProviderProcedures.forEach((providerProcedure) => {
        if (
          row.cptCode === providerProcedure.procedure.cptCode &&
          row.specialty === providerProcedure.provider.specialty &&
          row.procedureId === providerProcedure.procedureId &&
          !providerData.find((p) => p.id === providerProcedure.provider.id)
        ) {
          providerData.push({
            id: providerProcedure.provider.id,
            name: providerProcedure.provider.name,
            specialty: providerProcedure.provider.specialty,
            priceInCents: providerProcedure.priceInCents,
          });
        }
      });
      return {
        ...row,
        providerDropdown: providerData,
      };
    });

    dispatch(setAutoCompleteValue(autoCompleteValue));
    dispatch(
      setSelectedProcedures(selectedProcedureArrayWithDefaultDescriptions)
    );
    dispatch(setRows(rowsWithAllAvailableProviders));
    setCurrentProviderProcedures(fetchedProviderProcedures);
  };

  useEffect(() => {
    if (orderType !== orderTypeConstants.PATIENT_RESPONSIBILITY) {
      initializeDefault();
    }
  }, []);

  const methods = useForm({
    defaultValues: {
      email: patient.email,
      amount: centsToDollars(
        (serviceDetails as IGFEServiceDetails)
          .patientResponsibilityEstimateAmountInCents || 0
      ),
      lastName: patient.lastName,
      firstName: patient.firstName,
      dateOfBirth: parseDate(patient.dateOfBirth),
      totalAmount: centsToDollars(amountInCents || 0),
      phoneNumber: patient.phoneNumber
        ? formatPhoneNumberToMask(patient.phoneNumber)
        : null,
      accountNumber,
      dateOfService: parseDate(dateOfService),
      timeOfService: parseTime(dateOfService),
      serviceDetails: serviceDetails.procedures,
      preferredContactMethod: patient.preferredContactMethod,
      estimatedVisitCostForPatient: estimatedVisitCost,
      orderType,
      accountId,
      payerName: payerName?.name || null,
      deductible: centsToDollars(gfeQuote?.remainingDeductibleInCents || 0),
      outOfPocketMax: centsToDollars(gfeQuote?.outOfPocketMaxInCents || 0),
      flatCopay: centsToDollars(gfeQuote?.flatCopayInCents || 0),
      coinsurancePercent: gfeQuote?.coinsurancePercent || 0,
      gfeQuoteId,
      practiceType: mapPayerNameToCodeForDropdown[0]?.value,
      outOfPocketReason: serviceDetails?.outOfPocketReason
        ? { id: "", name: serviceDetails?.outOfPocketReason }
        : null,
      orderId: id,
      subscriberFirstName: patient.firstName,
      subscriberLastName: patient.lastName,
      subscriberDOB: parseDate(patient.dateOfBirth),
      memberId: patient?.patientBenefits?.memberID || null,
      isSubscriberPatient:
        patient?.patientBenefits?.isSubscriberPatient || null,
      paymentPlanDuration: paymentPlanActive?.paymentPlanDuration || null,
      paymentPlanMinDownPaymentType:
        paymentPlanActive?.paymentPlanMinDownPaymentFlat !== null &&
        paymentPlanActive?.paymentPlanMinDownPaymentFlat !== undefined
          ? "Flat amount"
          : "Percentage",
      paymentPlanMinDownPaymentFlat:
        paymentPlanActive?.paymentPlanMinDownPaymentFlat !== null &&
        paymentPlanActive?.paymentPlanMinDownPaymentFlat !== undefined
          ? centsToDollars(paymentPlanActive.paymentPlanMinDownPaymentFlat)
          : null,
      paymentPlanMinDownPaymentPercent:
        paymentPlanActive?.paymentPlanMinDownPaymentPercent !== null
          ? paymentPlanActive?.paymentPlanMinDownPaymentPercent
          : null,
      paymentPlanEnabled: !!paymentPlanActive,
      redoOrderFirstRender: true,
      outreachEnabled: !outreachStopped,
      providerDistributionLogic:
        paymentPlanActive?.providerDistributionLogic ||
        account?.providerDistributionLogic ||
        distributionLogicValueList.BY_PRIORITY,
    },
  });

  return (
    <FormProvider {...methods}>
      {account && (
        <RedoHeader
          accountName={account.name}
          handleSwitchRedoOrder={handleSwitchRedoOrder}
        />
      )}
      <OrderType
        orderTypesVisible={account?.orderTypesVisible}
        isFetching={false}
        redo
      />
      <PatientInfoForm />
      <PayerForm redo />
      <PatientBenefitsForm />
      <ServiceDetails
        redo
        selectedProceduresList={selectedProceduresList}
        totalAmount={order.amountInCents}
        currentProviderProcedures={currentProviderProcedures}
      />
      {!!account && (
        <PaymentPlan
          accountData={{ data: account }}
          paymentPlan={paymentPlanActive}
          isRedo
        />
      )}
      <VisitForm />
      {account && <Communication account={account} redo />}
      <Submit redo />
    </FormProvider>
  );
}
