// Same day as Date of Creation
// 1 day before Date of Service
// Same day as Date of Service (Default)

export enum availabilityList {
  SAME_DAY_DATE_CREATION = "Same day as Date of Creation",
  THREE_DAY_BEFORE_DATE_OF_SERVICE = "Three days before Date of Service",
  SAME_DAY_DATE_OF_SERVICE = "Same day as Date of Service",
}

const labelsDict = {
  [availabilityList.SAME_DAY_DATE_CREATION]: "Same day as Date of Creation",
  [availabilityList.THREE_DAY_BEFORE_DATE_OF_SERVICE]:
    "Three days before Date of Service",
  [availabilityList.SAME_DAY_DATE_OF_SERVICE]: "Same day as Date of Service",
};

export const paymentAvailability = {
  [availabilityList.SAME_DAY_DATE_CREATION]: {
    label: labelsDict[availabilityList.SAME_DAY_DATE_CREATION],
    value: availabilityList.SAME_DAY_DATE_CREATION,
  },
  [availabilityList.THREE_DAY_BEFORE_DATE_OF_SERVICE]: {
    label: labelsDict[availabilityList.THREE_DAY_BEFORE_DATE_OF_SERVICE],
    value: availabilityList.THREE_DAY_BEFORE_DATE_OF_SERVICE,
  },
  [availabilityList.SAME_DAY_DATE_OF_SERVICE]: {
    label: labelsDict[availabilityList.SAME_DAY_DATE_OF_SERVICE],
    value: availabilityList.SAME_DAY_DATE_OF_SERVICE,
  },
};

export enum distributionLogicValueList {
  BY_PRIORITY = "ByPriority",
  EQUAL_DISTRIBUTION = "EqualDistribution",
}

export const distributionLogicLabelList = {
  [distributionLogicValueList.BY_PRIORITY]: "By Priority",
  [distributionLogicValueList.EQUAL_DISTRIBUTION]: "Equal Distribution",
};

export const distributionLogicOptions = {
  [distributionLogicValueList.BY_PRIORITY]: {
    label: distributionLogicLabelList[distributionLogicValueList.BY_PRIORITY],
    value: distributionLogicValueList.BY_PRIORITY,
  },
  [distributionLogicValueList.EQUAL_DISTRIBUTION]: {
    label:
      distributionLogicLabelList[distributionLogicValueList.EQUAL_DISTRIBUTION],
    value: distributionLogicValueList.EQUAL_DISTRIBUTION,
  },
};
