import { ItemBarLeft } from "components/ItemBar";
import { ToastContext } from "context/ToastContext";
import { useQuery } from "hooks/useQuery";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useCancelSingleOrderMutation } from "store/queries/order";

export function CancelReviewOrder({ orderId }: { orderId: string }) {
  const { current: toastElement } = useContext(ToastContext);
  const navigate = useNavigate();
  const query = useQuery();
  const accountId = query.get("accountId") || undefined;
  const [cancelSingleOrder] = useCancelSingleOrderMutation();

  const cancelOrder = () => {
    cancelSingleOrder({ orderId })
      .unwrap()
      .then(() => {
        navigate(`/hospitals/view/${accountId}?tab=0`);
        toastElement?.show({
          severity: "success",
          summary: "Order canceled",
          detail: "Order canceled successfully.",
        });
      })
      .catch((error) =>
        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail: `Message: ${error}`,
        })
      );
  };

  const handleOnClickToCancelOrder = () => {
    confirmDialog({
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      message: `Are you sure you want to cancel this order?`,
      accept: cancelOrder,
    });
  };

  return (
    <ItemBarLeft>
      <div data-testid="saveDraft_ReviewOrder">
        <Button
          data-testid="cancel-order_OrderReview"
          className="ml-3"
          onClick={handleOnClickToCancelOrder}
        >
          <p className="small-text">Cancel Order</p>
        </Button>
      </div>
    </ItemBarLeft>
  );
}
