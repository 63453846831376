import { ISetting } from "../components/PaymentAndRemit/CustomPaymentPlanItem";

export const checkForOverlappingRanges = ({
  settings,
}: {
  settings: ISetting[];
}) => {
  if (settings.length === 0) return false;
  const sortedSettings = [...settings].sort(
    (a, b) => (a.min ?? Infinity) - (b.min ?? Infinity)
  );
  for (let i = 0; i < sortedSettings.length - 1; i += 1) {
    const current = sortedSettings[i];
    const next = sortedSettings[i + 1];

    if (current.max === null) {
      return true;
    }
    if (current.max >= (next.min ?? -Infinity)) {
      return true;
    }
  }

  return false;
};

export const validaTeCustomPaymentPlanSettings = ({
  settings,
}: {
  settings: ISetting[];
}) => {
  if (settings.length === 0) return true;
  const hasOverlappingRanges = checkForOverlappingRanges({ settings });
  if (hasOverlappingRanges) return false;
  for (let i = 0; i < settings.length; i += 1) {
    const setting = settings[i];
    if (setting.min === null) return false;
    if (setting.enabled) {
      const isPercentAndNull =
        setting.minDownPaymentType === "percent" &&
        setting.minDownPaymentPercent === null;
      const isFlatAndNull =
        setting.minDownPaymentType === "flat" &&
        setting.minDownPaymentFlat === null;
      const isNumberOfMonthsNull = setting.paymentPlanDuration === null;
      if (isPercentAndNull || isFlatAndNull || isNumberOfMonthsNull)
        return false;
    }
  }
  return true;
};
