import { validationConstants } from "constants/formValidation";
import { orderTypeConstants } from "constants/order";
import useFormValidation from "hooks/useFormValidation";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import {
  validateInputDatePicker,
  validateInputTime,
} from "pages/CreateOrder/utils/helpers";
import {
  handleChangeDateWithMask,
  handleChangeTimeWithMask,
} from "pages/CreateOrder/utils/mask";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

export interface IVisit {
  orderType: string;
  dateOfService: string;
  timeOfService: string;
  accountNumber: number;
  outOfPocketReason: string;
  estimatedVisitCostForPatient: number;
}

const StyledCard = styled(Card)`
  margin-bottom: ${sizer(4)};
`;

export const outOfPocketReasons = [
  {
    id: "",
    name: "You do not have an active insurance plan",
  },
  {
    id: "",
    name: "Your insurance does not cover this procedure",
  },
  {
    id: "",
    name: "You have not yet met your deductible",
  },
];

export function VisitForm() {
  const {
    watch,
    control,
    trigger,
    formState: { errors },
  } = useFormContext<IVisit>();

  const orderType = watch("orderType");

  const { handleOnBlurField, getFormErrorMessage } = useFormValidation();

  const showOutOfPocketReason = orderType === orderTypeConstants.BUNDLED;

  return (
    <StyledCard data-testid="visitTitle" title="Visit">
      <div className="flex flex-column gap-4 w-100">
        <div className="formgrid grid w-100">
          <div className="field flex flex-column col-12 md:col-3">
            <Label htmlFor="dateOfService">Date of Service</Label>

            <Controller
              data-testid="dateOfService"
              name="dateOfService"
              control={control}
              rules={{
                validate: (date) =>
                  validateInputDatePicker(date, "any", orderType),
                required: validationConstants.VISIT_INFO_FORM.DATE_OF_SERVICE,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id="dateOfService"
                    value={value || ""}
                    placeholder="99/99/9999"
                    onBlur={() =>
                      handleOnBlurField({
                        onBlur,
                        field: "dateOfService",
                        trigger,
                      })
                    }
                    onChange={(e) => handleChangeDateWithMask(e, onChange)}
                  />

                  {getFormErrorMessage("dateOfService", errors)}
                </>
              )}
            />
          </div>

          <div className="field flex flex-column col-12 md:col-3">
            <Label htmlFor="timeOfService">Time of Service</Label>

            <Controller
              control={control}
              name="timeOfService"
              data-testid="timeOfService"
              rules={{
                validate: (time) => validateInputTime(time),
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id="timeOfService"
                    value={value || ""}
                    placeholder="HH:MM PM"
                    onBlur={() =>
                      handleOnBlurField({
                        onBlur,
                        field: "timeOfService",
                        trigger,
                      })
                    }
                    onChange={(e) => handleChangeTimeWithMask(e, onChange)}
                  />

                  {getFormErrorMessage("timeOfService", errors)}
                </>
              )}
            />
          </div>

          <div className="field flex flex-column col-12 md:col-3">
            <Label htmlFor="accountNumber">Account/Visit ID</Label>

            <Controller
              name="accountNumber"
              control={control}
              rules={{
                required: validationConstants.VISIT_INFO_FORM.VISIT_ID,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    data-testid="accountNumber"
                    id="accountNumber"
                    placeholder="Account ID"
                    autoComplete="off"
                    value={value || ""}
                    onBlur={() =>
                      handleOnBlurField({
                        onBlur,
                        field: "accountNumber",
                        trigger,
                      })
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />

                  {getFormErrorMessage("accountNumber", errors)}
                </>
              )}
            />
          </div>

          {showOutOfPocketReason && (
            <div className="field flex flex-column col-12 md:col-3">
              <Label htmlFor="outOfPocketReason">Out of pocket reason</Label>

              <Controller
                data-testid="outOfPocketReason"
                name="outOfPocketReason"
                control={control}
                rules={{
                  required:
                    validationConstants.VISIT_INFO_FORM.OUT_OF_POCKET_REASON,
                }}
                render={({ field: { onChange, value, onBlur } }) => (
                  <Dropdown
                    id="outOfPocketReason"
                    data-testid="outOfPocketReason"
                    value={value || ""}
                    onChange={onChange}
                    options={outOfPocketReasons}
                    optionLabel="name"
                    placeholder="Select reason"
                    className="w-full"
                    onBlur={() =>
                      handleOnBlurField({
                        onBlur,
                        field: "outOfPocketReason",
                        trigger,
                      })
                    }
                  />
                )}
              />
            </div>
          )}
        </div>
      </div>
    </StyledCard>
  );
}
