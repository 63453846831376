import { publicApi } from ".";

const stripeQueries = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    attachPaymentMethod: builder.mutation({
      query: ({ paymentMethodId, customerId }) => ({
        url: "/stripe/attach/payment_methods",
        method: "POST",
        body: {
          paymentMethodId,
          customerId,
        },
      }),
    }),
    getCardLastDigits: builder.query({
      query: (stripeMethodId) => ({
        method: "GET",
        url: `/stripe/card_details/${stripeMethodId}`,
      }),
    }),
  }),
});

export const { useAttachPaymentMethodMutation, useGetCardLastDigitsQuery } =
  stripeQueries;
