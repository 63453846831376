import { TabPanel, TabView } from "primereact/tabview";

import { PatientsTable } from "./components/PatientsTable";

export function PatientsPage() {
  return (
    <TabView className="w-full">
      <TabPanel header="Patients">
        <PatientsTable showMessage={false} />
      </TabPanel>
    </TabView>
  );
}
