import styled from "styled-components";

interface IHorizontalLineProps {
  marginTop?: string;
  marginBottom?: string;
}

export const HorizontalLine = styled.hr<IHorizontalLineProps>`
  border-top: 1px solid #fff;
  margin-top: ${(props) => props.marginTop || "16px"};
  margin-bottom: ${(props) => props.marginBottom || "16px"};
`;
