import { Text, View } from "@react-pdf/renderer";

export function V3Disclaimer({
  hoursEstimated,
}: {
  hoursEstimated?: number | null;
}) {
  return (
    <View
      style={{
        fontFamily: "Helvetica",
        paddingLeft: "50px",
        paddingRight: "50px",
        marginBottom: 4,
        marginTop: 0,
        display: "flex",
        flexDirection: "column",
        fontSize: 11,
        textAlign: "justify",
      }}
    >
      <Text
        style={{
          fontFamily: "Helvetica-Bold",
          marginBottom: 8,
          textDecoration: "underline",
          textAlign: "center",
        }}
      >
        Important disclaimer
      </Text>
      <Text
        style={{
          fontFamily: "Helvetica-Bold",
          marginBottom: 8,
        }}
      >
        We have estimated your surgery to be {hoursEstimated || 0} hours,
        however, the actual time of surgery may vary.
      </Text>
      <Text
        style={{
          marginBottom: 8,
        }}
      >
        The total out-of-pocket cosmetic costs for the following fees are due as
        follows:
      </Text>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: 12,
        }}
      >
        <Text style={{ marginBottom: 4, lineHeight: 1.2 }}>
          1.{" "}
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
            }}
          >
            Surgeon fees
          </Text>
          – Payment is due{" "}
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
            }}
          >
            two weeks before your surgery date.
          </Text>
        </Text>
        <Text style={{ marginBottom: 4, lineHeight: 1.2 }}>
          2.{" "}
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
            }}
          >
            Anesthesia fees
          </Text>
          - Payment is due{" "}
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
            }}
          >
            two weeks before your surgery date.
          </Text>
        </Text>
        <Text style={{ marginBottom: 4, lineHeight: 1.2 }}>
          3.{" "}
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
            }}
          >
            Facility fees
          </Text>
          – Payment is due{" "}
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
            }}
          >
            two weeks before your surgery.
          </Text>{" "}
          *Refer to the fee payment schedule for the latest due date details.
        </Text>
      </View>
    </View>
  );
}
