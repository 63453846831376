import { centsToDollars, dollarsToCents } from "helpers/price";
import { Label } from "layout/typography/Label";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { ToggleButton } from "primereact/togglebutton";
import { useMemo } from "react";
import styled from "styled-components";

import { formValidationConstants } from "../../helpers/formValidations";

export interface ISetting {
  min: number | null;
  max: number | null;
  enabled: boolean;
  paymentPlanDuration: number;
  minDownPaymentType: "percent" | "flat";
  minDownPaymentPercent: number | null;
  minDownPaymentFlat: number | null;
}

interface ICustomPaymentPlanItemProps {
  setting: ISetting;
  settings: ISetting[];
  index: number;
  setSettings: (settings: any) => void;
}

const StyledButton = styled(Button)`
  padding: 0px !important;
  width: fit-content !important;
  height: fit-content;
`;

export function CustomPaymentPlanItem({
  setting,
  settings,
  index,
  setSettings,
}: ICustomPaymentPlanItemProps) {
  const handleAmountChange = (value: number | null, type: "min" | "max") => {
    const newSettings = [...settings];
    newSettings[index][type] = value === null ? null : dollarsToCents(value);
    setSettings(newSettings);
  };

  const handleRemoveSetting = () => {
    const newSettings = [...settings];
    newSettings.splice(index, 1);
    setSettings(newSettings);
  };

  const handleEnableDisablePaymentPlan = (e: any) => {
    const newValue = e.value;
    const newSettings = [...settings];
    newSettings[index].enabled = newValue;
    if (newValue === false) {
      newSettings[index].minDownPaymentPercent = null;
      newSettings[index].minDownPaymentFlat = null;
      newSettings[index].minDownPaymentType = "percent";
      newSettings[index].paymentPlanDuration = 0;
    }
    setSettings(newSettings);
  };

  const handleChangeMinDownPaymentType = (e: any) => {
    const newType = e.value === "Percentage" ? "percent" : "flat";
    const newSettings = [...settings];
    newSettings[index].minDownPaymentType = newType;
    if (newType === "percent") {
      newSettings[index].minDownPaymentFlat = null;
    } else {
      newSettings[index].minDownPaymentPercent = null;
    }
    setSettings(newSettings);
  };

  const handleMinDownPaymentChange = (
    value: number | null,
    type: "percent" | "flat"
  ) => {
    const settingType =
      type === "percent" ? "minDownPaymentPercent" : "minDownPaymentFlat";
    const newSettings = [...settings];
    if (type === "flat") {
      value = value === null ? null : dollarsToCents(value);
    }
    newSettings[index][settingType] = value;
    setSettings(newSettings);
  };

  const handleChangePaymentPlanDuration = (e: any) => {
    const { value } = e;
    const newSettings = [...settings];
    newSettings[index].paymentPlanDuration = value;
    setSettings(newSettings);
  };

  const showMinDownPaymentError = useMemo(() => {
    const isPercentAndNull =
      setting.minDownPaymentType === "percent" &&
      setting.minDownPaymentPercent === null;
    const isFlatAndNull =
      setting.minDownPaymentType === "flat" &&
      setting.minDownPaymentFlat === null;
    if (isPercentAndNull || isFlatAndNull) {
      return true;
    }
    return false;
  }, [settings]);

  return (
    <div
      className="flex flex-wrap w-full col-12 mb-2 align-items-baseline mt-2"
      style={{ minHeight: "94px" }}
    >
      <StyledButton
        onClick={handleRemoveSetting}
        type="button"
        icon="pi pi-trash"
        className="p-button-danger p-button-outlined p-button-rounded border-none align-self-center mr-3"
      />
      <div className="flex flex-column gap-1 mr-2">
        <Label htmlFor={`customSettingMin-${index}`}>Minimum amount</Label>
        <InputNumber
          min={0}
          value={setting.min === null ? null : centsToDollars(setting.min)}
          onValueChange={(e) => handleAmountChange(e.value, "min")}
        />
        {setting.min === null && (
          <p className="text-red-500 text-sm">
            {formValidationConstants.PAYMENT_PLAN_CUSTOM_SETTINGS.MIN_AMOUNT}
          </p>
        )}
      </div>
      <div className="flex flex-column gap-1">
        <Label htmlFor={`customSettingMax-${index}`}>Maximum amount</Label>
        <InputNumber
          min={0}
          value={setting.max === null ? null : centsToDollars(setting.max)}
          onValueChange={(e) => handleAmountChange(e.value, "max")}
        />
      </div>
      <div className="flex flex-column gap-1 mr-2 ml-5">
        <Label htmlFor={`customSettingEnabled-${index}`}>
          Payment Plan Enabled
        </Label>
        <ToggleButton
          checked={setting.enabled}
          onChange={handleEnableDisablePaymentPlan}
          className="w-8rem"
        />
      </div>
      {setting.enabled && (
        <>
          <div className="flex flex-column gap-1 mr-2 ml-5">
            <Label htmlFor={`customSettingMinDownPaymentType-${index}`}>
              Minimum down payment type
            </Label>
            <Dropdown
              id="customSettingMinDownPaymentType"
              data-testid="customSettingMinDownPaymentType"
              value={
                setting.minDownPaymentType === "percent"
                  ? "Percentage"
                  : "Flat amount"
              }
              onChange={handleChangeMinDownPaymentType}
              options={["Percentage", "Flat amount"]}
              placeholder="Select minimum down payment type"
              className="w-full"
            />
          </div>
          <div className="flex flex-column gap-1 mr-2 ml-5">
            <Label htmlFor={`customSettingDownPaymentPercent-${index}`}>
              {setting.minDownPaymentType === "percent"
                ? "Minimum first payment percent"
                : "Minimum first payment flat"}
            </Label>
            {setting.minDownPaymentType === "percent" ? (
              <InputNumber
                value={setting.minDownPaymentPercent}
                suffix="%"
                inputId="customSettingDownPaymentPercent"
                data-testid="customSettingDownPaymentPercent"
                onValueChange={(e) =>
                  handleMinDownPaymentChange(e.value, "percent")
                }
                min={5}
                max={100}
              />
            ) : (
              <InputNumber
                value={
                  setting.minDownPaymentFlat === null
                    ? null
                    : centsToDollars(setting.minDownPaymentFlat)
                }
                inputId="customSettingDownPaymentFlat"
                data-testid="customSettingDownPaymentFlat"
                currency="USD"
                locale="en-US"
                mode="currency"
                onValueChange={(e) =>
                  handleMinDownPaymentChange(e.value, "flat")
                }
                min={10}
                aria-autocomplete="none"
              />
            )}
            {showMinDownPaymentError && (
              <p className="text-red-500 text-sm">
                {
                  formValidationConstants.PAYMENT_PLAN_CUSTOM_SETTINGS
                    .MIN_DOWN_PAYMENT_PERCENT
                }
              </p>
            )}
          </div>
          <div className="flex flex-column gap-1 mr-2 ml-5">
            <Label htmlFor={`customSettingPaymentPlanDuration-${index}`}>
              Number of months
            </Label>
            <InputNumber
              value={setting.paymentPlanDuration}
              inputId="customSettingPaymentPlanDuration"
              data-testid="customSettingPaymentPlanDuration"
              onValueChange={handleChangePaymentPlanDuration}
              min={1}
              max={48}
              placeholder="Select number of months"
            />
          </div>
        </>
      )}
    </div>
  );
}
