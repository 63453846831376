import { Text, View } from "@react-pdf/renderer";
import { centsToDollars, parsePrice } from "helpers/price";

import { styles } from "../styles";

export type Props = {
  surgeonFees: number;
  anesthesiaFees: number;
  facilityFees: number;
  scd: number;
  exparel: number;
  clearPriceToPay: number;
};

export function ChargeDescriptionV3({
  surgeonFees,
  anesthesiaFees,
  facilityFees,
  scd,
  exparel,
  clearPriceToPay,
}: Props) {
  return (
    <View style={{ marginLeft: 50, marginRight: 50 }}>
      <View
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <View style={styles.table}>
          <View style={[styles.row, styles.headerRow]}>
            <Text style={(styles.cell, styles.leftCell)}>
              CHARGE DESCRIPTION
            </Text>
            <Text style={(styles.cell, styles.rightCell)}>AMOUNT</Text>
          </View>

          <>
            <View style={[styles.row, styles.grayBackground]}>
              <Text style={[styles.cell, styles.leftCell]}>Surgeon fees</Text>
              <Text
                style={[styles.cell, styles.rightCell, styles.rightCellBorder]}
              >
                {parsePrice(centsToDollars(surgeonFees))}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={[styles.cell, styles.leftCell]}>
                Anesthesia fees
              </Text>
              <Text
                style={[styles.cell, styles.rightCell, styles.rightCellBorder]}
              >
                {parsePrice(centsToDollars(anesthesiaFees))}
              </Text>
            </View>
            <View style={[styles.row, styles.grayBackground]}>
              <Text style={[styles.cell, styles.leftCell]}>Facility fees</Text>
              <Text
                style={[styles.cell, styles.rightCell, styles.rightCellBorder]}
              >
                {parsePrice(centsToDollars(facilityFees))}
              </Text>
            </View>
            {exparel && (
              <View style={styles.row}>
                <Text style={[styles.cell, styles.leftCell]}>Exparel</Text>
                <Text
                  style={[
                    styles.cell,
                    styles.rightCell,
                    styles.rightCellBorder,
                  ]}
                >
                  {parsePrice(centsToDollars(exparel))}
                </Text>
              </View>
            )}
            {scd && (
              <View style={{ ...styles.row, backgroundColor: "#a5c9eb" }}>
                <Text style={[styles.cell, styles.leftCell]}>SCD</Text>
                <Text
                  style={[
                    styles.cell,
                    styles.rightCell,
                    styles.rightCellBorder,
                  ]}
                >
                  {parsePrice(centsToDollars(scd))}
                </Text>
              </View>
            )}
            <View
              style={{
                ...styles.row,
                backgroundColor: "#426393",
                color: "#fff",
                fontSize: 12,
                fontFamily: "Helvetica-Bold",
                fontWeight: 900,
              }}
            >
              <Text style={[styles.cell, styles.leftCell]}>
                Total Responsibility
              </Text>
              <Text
                style={[styles.cell, styles.rightCell, styles.rightCellBorder]}
              >
                {parsePrice(centsToDollars(clearPriceToPay))}
              </Text>
            </View>
          </>
        </View>
      </View>
    </View>
  );
}
