import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { useState } from "react";
import styled from "styled-components";

const NoteToPdfContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 330px;
`;

type NoteToPdfDialogProps = {
  showDialog: boolean;
  setShowDialog: (show: boolean) => void;
  onSaveNote: (note: string) => void;
  note: string;
  setNote: (note: string) => void;
  showHoursEstimated: boolean;
  hoursEstimated: number | null;
  setHoursEstimated: (hours: number | null) => void;
};

export function NoteToPdfDialog({
  showDialog,
  setShowDialog,
  onSaveNote,
  note,
  setNote,
  showHoursEstimated,
  hoursEstimated,
  setHoursEstimated,
}: NoteToPdfDialogProps) {
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(
    showHoursEstimated
      ? hoursEstimated === null || hoursEstimated === undefined
      : false
  );
  const footerComponent = () => (
    <div>
      <Button
        label="Cancel"
        onClick={() => {
          setNote("");
          setShowDialog(false);
        }}
        className="p-button-link mr-2"
      />
      <Button
        label="Save"
        disabled={saveButtonDisabled}
        onClick={() => {
          onSaveNote(note);
          setNote("");
          setShowDialog(false);
        }}
      />
    </div>
  );

  return (
    <Dialog
      onHide={() => {
        setNote("");
        setShowDialog(false);
      }}
      visible={showDialog}
      header="Add Note for PDF"
      footer={footerComponent}
    >
      <NoteToPdfContainer>
        <span>Please, add your note here!</span>
        <InputTextarea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          rows={2}
          placeholder="Notes"
          className="w-full mt-3"
        />
        {showHoursEstimated && (
          <div className="flex  flex-column gap-1 mt-3">
            Estimated Hours
            <InputNumber
              value={hoursEstimated}
              style={{ width: "54px" }}
              min={0}
              minFractionDigits={1}
              maxFractionDigits={2}
              onValueChange={({ value }) => {
                setHoursEstimated(value);
              }}
              onChange={({ value }) =>
                setSaveButtonDisabled(value === null || value === undefined)
              }
            />
          </div>
        )}
      </NoteToPdfContainer>
    </Dialog>
  );
}
