import { LoadingSpinner } from "components/LoadingSpinner";
import { AccountFeeType } from "constants/fee";
import { isElementDuplicated } from "helpers/array";
import {
  calculateDiscount,
  calculateDiscountPercent,
  calculateProviderFinalPayment,
} from "helpers/price";
import { roundToX } from "helpers/round";
import { useGetAccountDiscountAndFee } from "hooks/useGetAccountDiscountAndFee";
import { useQuery } from "hooks/useQuery";
import { sizer } from "layout/styles/styled/sizer";
import { StyledCard } from "pages/CreateOrder/CreateOrder.styled";
import {
  AutoComplete,
  AutoCompleteSelectParams,
} from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useGetAccountByIdQuery } from "store/queries/account";
import { useGetBillingCodesQuery } from "store/queries/billingCode";
import styled from "styled-components";
import { IBillingCode } from "types/BillingCode";

import { FieldsContainer } from "../../ServiceDetails";

const Discount = styled.p`
  text-align: end;
  color: var(--color-purple);
  font-weight: var(--font-weight-semibold);
  font-size: var(--fontsize-contents);
  margin-right: 50px;

  span {
    font-weight: 400;
    color: var(--bluegray-500);
  }
`;

export type ServiceDetails = {
  amount: any;
  totalAmount: any;
  serviceDetails: Array<{ code: string; cptDescription: string }>;
  providerFinalPayment: any;
};

export function PatientResponsibilityServiceDetails({
  selectedProceduresList,
  redo,
  draft,
  copy,
  isFirstRender,
}: {
  selectedProceduresList?: string[];
  redo?: boolean;
  draft?: boolean;
  copy?: boolean;
  isFirstRender?: boolean;
}) {
  const query = useQuery();
  const accountId = query.get("accountId");
  const { setValue, control, watch } = useFormContext<ServiceDetails>();

  const { data: billingCodesFromAPI } = useGetBillingCodesQuery();
  const { data: accountData, isLoading: isAccountDataLoading } =
    useGetAccountByIdQuery(accountId || "");
  const billingCodes = billingCodesFromAPI?.data || [];

  const [selectedBillingCode, setSelectedBillingCode] = useState<string[]>(
    selectedProceduresList || []
  );
  const [filteredBillingCodes, setFilteredBillingCodes] = useState<
    IBillingCode[]
  >([]);

  const parsedFilteredBillingCodes = useMemo(() => {
    return filteredBillingCodes.map(
      (billingCode: IBillingCode) =>
        `${billingCode.name} - ${billingCode.description}`
    );
  }, [filteredBillingCodes]);

  const parsedSelectedBillingCodesToTable = useMemo(() => {
    if (!selectedBillingCode) return [];

    return selectedBillingCode.map((selectedBillingCode) => {
      const billingCodeName = selectedBillingCode.split("-")[0];
      const billingCodeDescription = selectedBillingCode
        .split("-")
        .slice(1)
        .join("-");

      return {
        code: billingCodeName,
        cptDescription: billingCodeDescription,
      };
    });
  }, [selectedBillingCode]);

  const search = (event: { query: string }) => {
    let filteredBillingCodesToSet;

    if (!event.query.trim().length) {
      filteredBillingCodesToSet = [...billingCodes];
    } else {
      filteredBillingCodesToSet = billingCodes.filter(
        (billingCode: IBillingCode) => {
          return `${billingCode.name} - ${billingCode.description}`
            .toLowerCase()
            .includes(event.query.toLowerCase());
        }
      );
    }

    setFilteredBillingCodes(filteredBillingCodesToSet);
  };

  const handleOnSelectBillingCode = (e: AutoCompleteSelectParams) => {
    const billingCodeAlreadySelected = selectedBillingCode.find(
      (currentBillingCode) => currentBillingCode === e.value
    );

    if (billingCodeAlreadySelected) {
      setSelectedBillingCode((prevState) => [...prevState, e.value]);
    }
  };

  const handleOnUnSelectBillingCode = (e: AutoCompleteSelectParams) => {
    const isDuplicated = isElementDuplicated(selectedBillingCode, e.value);

    if (!isDuplicated) return;

    const billingCodeIndex = selectedBillingCode.findIndex(
      (currentBillingCode) => currentBillingCode === e.value
    );

    if (billingCodeIndex !== -1) {
      const newSelectedBillingCode = selectedBillingCode.splice(
        billingCodeIndex,
        1
      );

      setSelectedBillingCode(newSelectedBillingCode);
    }
  };

  const { accountDiscount, isLoading: isDiscountLoading } =
    useGetAccountDiscountAndFee(AccountFeeType.PRDiscount);

  const [customDiscountPercent, setCustomDiscountPercent] = useState<
    number | null
  >(null);

  const [isEditMode, setEditMode] = useState(false);

  const totalAmount = watch("totalAmount");
  const amount = watch("amount");

  const discount = useMemo(() => {
    if (
      isEditMode ||
      customDiscountPercent ||
      (redo && isFirstRender) ||
      (draft && isFirstRender) ||
      (copy && isFirstRender)
    ) {
      const newDiscount = calculateDiscountPercent(totalAmount, amount);
      const discountRounded = roundToX(newDiscount);

      setCustomDiscountPercent(discountRounded);

      return discountRounded;
    }

    return accountDiscount;
  }, [accountDiscount, isEditMode, amount]);

  useEffect(() => {
    setValue("serviceDetails", parsedSelectedBillingCodesToTable);
  }, [parsedSelectedBillingCodesToTable]);

  useEffect(() => {
    if (!totalAmount && isEditMode) return;
    const clearFeeValueInPercentage =
      accountData?.data?.accountFees?.length &&
      accountData.data.accountFees.find(
        (fee: any) => fee.type === AccountFeeType.CTFForPR
      )?.valueInPercentage;
    setValue("amount", calculateDiscount(totalAmount, discount));
    setValue(
      "providerFinalPayment",
      calculateProviderFinalPayment(amount, clearFeeValueInPercentage)
    );
  }, [totalAmount, isEditMode, accountData, amount]);

  return (
    <StyledCard
      title="Service"
      data-testid="serviceDetails_PatientResponsibilityServiceDetails"
    >
      <div className="formgrid grid w-100">
        <div className="field p-fluid col-12">
          <label htmlFor="code">CPT code</label>
          <AutoComplete
            multiple
            id="code"
            dropdown
            className="w-full"
            completeMethod={search}
            data-testid="autocomplete"
            value={selectedBillingCode}
            placeholder="Select CPT Code..."
            suggestions={parsedFilteredBillingCodes}
            onChange={(e) => setSelectedBillingCode(e.value)}
            onSelect={(e) => handleOnSelectBillingCode(e)}
            onUnselect={(e) => handleOnUnSelectBillingCode(e)}
          />
        </div>
      </div>

      <DataTable
        stripedRows
        responsiveLayout="scroll"
        emptyMessage="No CPT codes selected."
        value={parsedSelectedBillingCodesToTable}
        style={{
          marginLeft: sizer(-4),
          marginRight: sizer(-4),
        }}
      >
        <Column
          field="code"
          header="CPT Code"
          style={{ maxWidth: "80px", paddingLeft: sizer(6) }}
        />
        <Column field="cptDescription" header="Description" />
      </DataTable>

      {isDiscountLoading || isAccountDataLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <FieldsContainer>
            <div className="flex">
              <label
                htmlFor="totalAmount"
                className="mr-2 flex align-items-center"
              >
                Total Patient Estimate
              </label>

              <Controller
                name="totalAmount"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputNumber
                    currency="USD"
                    locale="en-US"
                    mode="currency"
                    value={value}
                    inputId="totalAmount"
                    onChange={({ value }) => onChange(value)}
                  />
                )}
              />
            </div>
          </FieldsContainer>

          <FieldsContainer>
            <div className="flex align-items-center">
              <label htmlFor="amount" className="mr-2 flex align-items-center">
                Clear Price for Patient
              </label>

              <Controller
                control={control}
                name="amount"
                render={({ field: { onChange, value } }) => (
                  <InputNumber
                    value={value}
                    currency="USD"
                    locale="en-US"
                    mode="currency"
                    inputId="amount"
                    disabled={!isEditMode}
                    onChange={({ value }) => onChange(value)}
                  />
                )}
              />

              <Button
                disabled={!totalAmount}
                style={{ width: 42, justifyContent: "center" }}
                className="p-button-text p-button-primary p-0 ml-2"
                onClick={() => setEditMode((prevState) => !prevState)}
              >
                <p className="small-text">{!isEditMode ? "Edit" : "Save"}</p>
              </Button>
            </div>
          </FieldsContainer>

          {Boolean(totalAmount) && (
            <Discount className="mt-3">
              {Boolean(customDiscountPercent) && (
                <span>Updated with entered price: </span>
              )}
              ({discount}% discount)
            </Discount>
          )}
          <FieldsContainer style={{ marginRight: "50px" }}>
            <div className="flex">
              <label
                htmlFor="totalAmount"
                className="mr-2 flex align-items-center"
              >
                Provider Final Payment
              </label>

              <Controller
                name="providerFinalPayment"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputNumber
                    disabled
                    currency="USD"
                    locale="en-US"
                    mode="currency"
                    value={value}
                    inputId="providerFinalPayment"
                    onChange={({ value }) => onChange(value)}
                  />
                )}
              />
            </div>
          </FieldsContainer>
        </div>
      )}
    </StyledCard>
  );
}
