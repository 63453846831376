interface IDetailItem {
  title: string;
  value: string;
}

function DetailItem({ title, value }: IDetailItem) {
  return (
    <div className="flex w-full justify-content-between">
      <p className="text-sm line-height-2 font-normal highlighted">{title}</p>
      <p className="text-xs line-height-2 font-normal gray">{value}</p>
    </div>
  );
}

export default DetailItem;
