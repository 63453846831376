/* eslint-disable prefer-destructuring */
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

export function parseDate(
  date: string | Date,
  formatted?: boolean,
  convertToLocal?: boolean
) {
  if (convertToLocal) {
    return formatted
      ? dayjs(date).format("MMMM D, YYYY")
      : dayjs(date).format("MM/DD/YYYY");
  }
  if (formatted) {
    return dayjs.utc(date).format("MMMM D, YYYY");
  }
  return dayjs.utc(date).format("MM/DD/YYYY");
}

export function parseTime(dateTime: string | Date, convertToLocal?: boolean) {
  const time = convertToLocal ? dayjs(dateTime) : dayjs(dateTime).utc();

  if (!time.hour() && !time.minute() && !time.second()) return "";
  return time.format("h:mm A");
}

export function parseDateInCST(date: string | Date, formatted?: boolean) {
  if (formatted) {
    return dayjs(date).tz("America/Chicago").format("MMMM D, YYYY"); // Use CST
  }

  return dayjs(date).tz("America/Chicago").format("MM/DD/YYYY"); // Use CST
}

export function parseTimeInCST(dateTime: string | Date) {
  const time = dayjs(dateTime).tz("America/Chicago"); // Convert to CST

  if (!time.hour() && !time.minute() && !time.second()) return "-";
  return time.format("h:mm A"); // Format time in CST
}

export function toUTCDate(value: string) {
  const date = new Date(value); // Parse the date
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  ).toISOString();
}
