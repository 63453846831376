import {
  distributionLogicValueList,
  availabilityList,
  paymentAvailability,
} from "constants/paymentPlanConstants";
import useDocumentTitle from "hooks/useDocumentTitle";
import {
  TypesCode,
  orderTypes,
} from "pages/CreateOrder/components/Header/constants";
import { useForm, FormProvider } from "react-hook-form";
import styled from "styled-components";
import { SpecialtyList } from "types/Provider/Provider";

import { specialtyDropdown } from "../../helpers/providerConstants";
import { ClearFee } from "./components/ClearFee";
import { Header } from "./components/Header";
import { HospitalDetails } from "./components/HospitalDetails";
import { OrderCommunicationDetails } from "./components/OrderCommunicationDetails";
import { PaymentAndRemit } from "./components/PaymentAndRemit";
import { Submit } from "./components/Submit";

const Space = styled.div`
  height: 20px;
`;

export function CreateHospitalPage() {
  useDocumentTitle("Create Hospital");

  const methods = useForm({
    defaultValues: {
      hospitalName: null,
      addressLine1: "",
      addressLine2: "",
      city: null,
      states: null,
      zipcode: null,
      rcm: null,
      specialty: specialtyDropdown[SpecialtyList.FACILITY].value,
      contactName: null,
      reportType: null,
      unitHospitalNumber: null,
      contactPhone: null,
      orderType: orderTypes[TypesCode.Bundled].value,
      taxId: null,
      contactEmail: null,
      emailToReceiveReports: [],
      connectAccountId: null,
      messageSender: "47397",
      emailSender: "donotreply@clearhealthinc.com",
      emailToReceiveNotification: [],
      frontDeskEmployeesToReceiveDailyEmail: [],
      publicContactName: "",
      publicContactRole: "",
      publicContactPhone: "",
      emailTemplateAttempt1: "d-f7507ca8d0be4789abb28d68c77fe613",
      attempt1:
        "Important notice from {{accountName}}: you have the opportunity to reduce your bill by {{savingPercent}} for your appointment on {{visitDate}} {{visitTime}} by paying prior to arrival. To take advantage of this limited time offer visit {{url}}.",
      emailTemplateAttempt2: "d-9196ce1a0a7e433ba14158b52e396ed4",
      attempt2:
        "Important Reminder from {{accountName}}: Save {{actualSavings}} on your upcoming appointment on {{visitDate}} by paying in advance. This special offer is part of our commitment to your care. To secure your savings, please visit {{url}}.",
      emailTemplateAttempt3: "d-1de313ebfd4b48f88697cfa158012e04",
      attempt3:
        "Your appointment is today. To ensure a smooth and efficient visit, kindly complete your payment online to save {{actualSavings}}. Visit {{url}} for a quick and secure transaction. Your well-being is our utmost concern, and we look forward to caring for you.",
      emailTemplateAttemptBundle1: "d-a2158da1706c4a23bbdaa798ad32c775",
      attemptBundle1:
        "Important notice from {{accountName}}: you have the opportunity to reduce your bill by {{savingPercent}} for your appointment on {{visitDate}} {{visitTime}} by paying prior to arrival. To take advantage of this limited time offer visit {{url}}.",
      emailTemplateAttemptBundle2: "d-ba328c5ec9844796adf4d731bb987920",
      attemptBundle2:
        "Important Reminder from {{accountName}}: Save {{actualSavings}} on your upcoming appointment on {{visitDate}} by paying in advance. This special offer is part of our commitment to your care. To secure your savings, please visit {{url}}.",
      emailTemplateAttemptBundle3: "d-f527a12fd7494fb6b9021d0727f3a284",
      attemptBundle3:
        "Your appointment is today. To ensure a smooth and efficient visit, kindly complete your payment online to save {{actualSavings}}. Visit {{url}} for a quick and secure transaction. Your well-being is our utmost concern, and we look forward to caring for you.",
      emailTemplateAttemptED1: "d-680121ce195e423688e4cc507f26c707",
      attemptED1:
        "{{accountName}} and Clear Health have partnered to offer you a reduced price for your emergency room visit on {{visitDate}}, if paid promptly. Please review your payment options now {{url}}.",
      emailTemplateAttemptED2: "d-2bb40584ab8b4f9099216d1a9aa13772",
      attemptED2:
        "Reminder: This offer to save on your emergency room visit will expire in 72 hours and you will be billed as normal for the full cost. {{url}}.",
      emailTemplateAttemptED3: "d-39a7417cdb674999b5991734632bc99d",
      attemptED3:
        "Last opportunity to take advantage of a one time discounted Emergency Room Payment offer {{url}}.",
      emailTemplateAttemptBariatrics1: "d-a2158da1706c4a23bbdaa798ad32c775",
      attemptBariatrics1:
        "Important notice from {{accountName}}: you have the opportunity to reduce your bill by {{savingPercent}} for your appointment on {{visitDate}} {{visitTime}} by paying prior to arrival. To take advantage of this limited time offer visit {{url}}.",
      emailTemplateAttemptBariatrics2: "d-ba328c5ec9844796adf4d731bb987920",
      attemptBariatrics2:
        "Important Reminder from {{accountName}}: Save {{actualSavings}} on your upcoming appointment on {{visitDate}} by paying in advance. This special offer is part of our commitment to your care. To secure your savings, please visit {{url}}.",
      emailTemplateAttemptBariatrics3: "d-f527a12fd7494fb6b9021d0727f3a284",
      attemptBariatrics3:
        "Your appointment is today. To ensure a smooth and efficient visit, kindly complete your payment online to save {{actualSavings}}. Visit {{url}} for a quick and secure transaction. Your well-being is our utmost concern, and we look forward to caring for you.",
      emailTemplateAttemptGFE1: "d-a2158da1706c4a23bbdaa798ad32c775",
      attemptGFE1:
        "Important notice from {{accountName}}: you have the opportunity to reduce your bill by {{savingPercent}} for your appointment on {{visitDate}} {{visitTime}} by paying prior to arrival. To take advantage of this limited time offer visit {{url}}.",
      emailTemplateAttemptGFE2: "d-ba328c5ec9844796adf4d731bb987920",
      attemptGFE2:
        "Important Reminder from {{accountName}}: Save {{actualSavings}} on your upcoming appointment on {{visitDate}} by paying in advance. This special offer is part of our commitment to your care. To secure your savings, please visit {{url}}.",
      emailTemplateAttemptGFE3: "d-f527a12fd7494fb6b9021d0727f3a284",
      attemptGFE3:
        "Your appointment is today. To ensure a smooth and efficient visit, kindly complete your payment online to save {{actualSavings}}. Visit {{url}} for a quick and secure transaction. Your well-being is our utmost concern, and we look forward to caring for you.",
      ptFeeMethod: null,
      clearFeeForClearPackage: 15,
      clearTransactionFeeForPR: 6,
      patientResponsibilityDiscount: 25,
      clearFinancingFee: 7,
      uploadImage: null,
      expirationDays: 2,
      clearHealthFeeForGFE: 6,
      clearHealthDiscountForGFE: 25,
      eligibilityEnabled: false,
      paymentPlanTypeEnabled: {
        patientResponsibility: false,
        ed: false,
        bariatrics: false,
        gfe: false,
        bundled: false,
      },
      patientResponsibilityVisible: false,
      edVisible: false,
      bariatricsVisible: false,
      gfeVisible: false,
      bundledVisible: false,
      paymentPlanDuration: 6,
      paymentPlanMinDownPaymentPercent: 50,
      paymentPlanAvailability:
        paymentAvailability[availabilityList.SAME_DAY_DATE_CREATION].value,
      paymentPlanCustomSettings: [],
      providerDistributionLogic: distributionLogicValueList.BY_PRIORITY,
    },
  });

  return (
    <FormProvider {...methods}>
      <Header />
      <HospitalDetails />
      <Space />
      <PaymentAndRemit />
      <Space />
      <OrderCommunicationDetails />
      <Space />
      <ClearFee />
      <Submit />
    </FormProvider>
  );
}
