import { LoadingSpinner } from "components/LoadingSpinner";
import { UserRole, userRoleMapping } from "constants/userRole";
import { useLoading } from "context/LoadingContext";
import { ToastContext } from "context/ToastContext";
import { parseDate } from "helpers/date";
import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useDeleteUserMutation,
  useGetAllUsersQuery,
} from "store/queries/users";
import styled from "styled-components";
import { IUser } from "types/User";

const StyledButton = styled(Button)`
  gap: ${sizer(2)};
`;

export function UsersTable() {
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();

  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const { current: toastElement } = useContext(ToastContext);

  const { data, isFetching, refetch } = useGetAllUsersQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const [deleteMutation] = useDeleteUserMutation();

  const usersInfo = useMemo(() => {
    const users = data?.data;

    return (
      users?.map((user) => ({
        ...user,
        scope: userRoleMapping[user.scope as UserRole],
        createdAt: parseDate(user.createdAt, false, true),
      })) || []
    );
  }, [data]);

  const handleOnClickDeleteUsers = async () => {
    setIsLoading(true);

    try {
      const deletePromises = selectedUsers.map((user) =>
        deleteMutation(user.id)
      );

      await Promise.all(deletePromises);
      await refetch();

      toastElement?.show({
        severity: "success",
        summary: "Deleted!",
        detail: "User(s) has been deleted successfully.",
      });
    } catch (error) {
      await refetch();

      toastElement?.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while deleting users. Please try again.",
      });
    } finally {
      setIsLoading(false);
      setSelectedUsers([]);
    }
  };

  const userScopeRowFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        placeholder="Select a Role"
        className="p-column-filter"
        options={Object.values(userRoleMapping)}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };

  const editUser = (userId: string) => {
    return (
      <Button
        className="p-button-link"
        onClick={() => navigate(`/users/edit/${userId}`)}
      >
        <p className="small-text">Edit</p>
      </Button>
    );
  };

  if (isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="flex justify-content-end mb-3">
        <StyledButton
          icon="pi pi-plus"
          className="p-button-raised"
          onClick={() => navigate("/users/new")}
        >
          New User
        </StyledButton>
      </div>
      <DataTable
        rows={10}
        paginator
        stripedRows
        value={usersInfo}
        className="p-fluid"
        filterDisplay="row"
        responsiveLayout="scroll"
        selection={selectedUsers}
        emptyMessage="No users to show."
        onSelectionChange={(event) => setSelectedUsers(event.value)}
      >
        <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />

        <Column
          filter
          field="name"
          header="Name"
          filterPlaceholder="John Smith"
        />
        <Column
          filter
          field="email"
          header="Email"
          filterPlaceholder="john.smith@clearhealthinc.com"
        />
        <Column
          filter
          field="scope"
          header="Scope"
          filterElement={userScopeRowFilterTemplate}
        />
        <Column
          filter
          field="createdAt"
          header="Created At"
          filterPlaceholder="mm/dd/yyyy"
        />

        <Column field="editUser" body={(row) => editUser(row.id)} />
      </DataTable>
      <div className="flex justify-content-end">
        <Button
          label="Delete"
          disabled={!selectedUsers.length}
          className="p-button-outlined mt-4"
          onClick={() => {
            confirmDialog({
              header: "Confirmation",
              icon: "pi pi-exclamation-triangle",
              message: `Are you sure you want delete?`,
              accept: handleOnClickDeleteUsers,
            });
          }}
        />
      </div>
    </>
  );
}
