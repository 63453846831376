import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { LoadingSpinner } from "components/LoadingSpinner";
import { ISPAConfig } from "config/api";
import { PaymentSection } from "pages/OrderOverview/components/PaymentSection";
import { DEFAULT_EXPIRATION_DAYS } from "pages/OrderOverview/utils/constants";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useCreateOrderPaymentIntentMutation } from "store/queries/order";

const getPublishableKey = () => {
  const config = window?.config as ISPAConfig;

  if (!config) {
    throw new Error("Config not found");
  }

  return config.stripe.publishableKey;
};

export function PayNowSection({
  expirationDays,
  orderInfo,
  showPaymentSection,
  setShowPaymentSection,
  clearPrice,
  totalSaving,
  fullPrice,
  payRemainingPrice,
}: {
  expirationDays?: number;
  orderInfo: any;
  showPaymentSection: any;
  setShowPaymentSection: any;
  clearPrice: number;
  totalSaving: number;
  fullPrice: number;
  payRemainingPrice: boolean;
}) {
  const { id: externalId } = useParams();

  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    setStripePromise(loadStripe(getPublishableKey()));
  }, []);

  const [
    createOrderPaymentIntent,
    { isError: isStripeError, isLoading: isStripeLoading },
  ] = useCreateOrderPaymentIntentMutation();

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    createOrderPaymentIntent({
      orderId: externalId || "",
      paymentPlan: false,
      payRemainingPrice,
    })
      .unwrap()
      .then(({ data }: { data: any }) => setClientSecret(data.client_secret));
  }, []);

  if (isStripeError) {
    return <Navigate to="/not-found" />;
  }

  if (isStripeLoading || !clientSecret) {
    return <LoadingSpinner />;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance: {
          rules: {
            ".Label": {
              color: "#64636E",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
            },
            ".Input": {
              borderRadius: "8px",
              border: "1px solid #D2D6DB",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              marginBottom: "12px",
            },
          },
        },
      }}
    >
      <PaymentSection
        expirationDays={expirationDays || DEFAULT_EXPIRATION_DAYS}
        order={orderInfo}
        prices={{ clearPrice, totalSaving, fullPrice }}
        onReturnClick={() => {
          setShowPaymentSection({
            paymentPlan: false,
            paymentSection: false,
            paymentSuccess: false,
          });
        }}
        showPaymentSection={showPaymentSection}
        setShowPaymentSection={setShowPaymentSection}
        payRemainingPrice={payRemainingPrice}
      />
    </Elements>
  );
}
