import {
  ED_ORDER_TYPE_EXPIRATION_PERIOD,
  orderStatusConstants,
  orderTypeConstants,
} from "constants/order";
import { availabilityList } from "constants/paymentPlanConstants";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
import { dollarsToCents } from "helpers/price";
import { isEmpty } from "lodash";
import { FieldValues } from "react-hook-form";
import { IPaymentPlanTypeEnabled } from "types/Account/Account";
import { IOrder, IOrderCreateRequest } from "types/Order/Order";
import { OrderType } from "types/Order/OrderType";

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

export function toCents(aValue: number) {
  return Math.round((Math.abs(aValue) / 100) * 10000);
}

export function removeTimezone(dataString: string): Date {
  return dayjs.utc(dataString).toDate();
}

export function convertToUTC(dateString: string) {
  const [month, day, year] = dateString.split("/").map(Number);

  // Create a Date object in UTC timezone
  const utcDate = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));

  return utcDate.toISOString();
}

export function validateInputTime(time: string) {
  if (!time) return true;

  const regex = /^(1[0-2]|0?[1-9]):([0-5][0-9]) ([APap][Mm])$/;
  return !regex.test(time) ? "Invalid time." : true;
}

export function validateInputDatePicker(
  input: string,
  blockDays: "past" | "future" | "any",
  orderType?: string
) {
  const pattern = /^\d{2}\/\d{2}\/\d{4}$/;

  if (!pattern.test(input)) {
    return "Invalid Date";
  }

  const parts = input.split("/");
  const month = parseInt(parts[0], 10);
  const day = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  if (
    month < 1 ||
    month > 12 ||
    day < 1 ||
    day > 31 ||
    year < 1000 ||
    year > 9999
  ) {
    return "Invalid Date";
  }

  const currentDate = dayjs().startOf("day");
  const selectedDate = dayjs(input).startOf("day");

  if (orderType === orderTypeConstants.ED) {
    const tenDaysAgo = currentDate.subtract(
      ED_ORDER_TYPE_EXPIRATION_PERIOD,
      "day"
    );
    if (selectedDate.isBetween(tenDaysAgo, currentDate, "days", "[]")) {
      return true;
    }
    return `Select a date between ${tenDaysAgo.format(
      "MM/DD/YYYY"
    )} and ${currentDate.format("MM/DD/YYYY")}`;
  }

  if (
    blockDays === "future" &&
    selectedDate.isSameOrAfter(currentDate, "date")
  ) {
    return true;
  }

  if (
    blockDays === "past" &&
    selectedDate.isSameOrBefore(currentDate, "date")
  ) {
    return true;
  }

  if (blockDays === "any") {
    return true;
  }

  return "Invalid Date";
}

const getServiceDetailsObjectByOrderType = ({
  orderType,
  serviceDetails,
  amount,
  outOfPocketReason,
  estimatedVisitCostForPatient,
  payerName,
  placeOfService,
  locality,
}: FieldValues) => {
  const patientResponsibilityObj = {
    patientResponsibilityEstimateAmountInCents: toCents(amount) || 0,
    procedures: serviceDetails || [],
  };

  const GFEObj = {
    procedures: serviceDetails || [],
    patientResponsibilityEstimateAmountInCents: toCents(amount) || 0,
    payerName,
    placeOfService,
    locality,
  };

  const defaultObj = {
    procedures: serviceDetails || [],
    estimatedVisitCost: toCents(estimatedVisitCostForPatient) || 0,
    outOfPocketReason: outOfPocketReason?.name,
  };

  switch (orderType) {
    case orderTypeConstants.PATIENT_RESPONSIBILITY:
      return patientResponsibilityObj;
    case orderTypeConstants.GFE:
      return GFEObj;
    default:
      return defaultObj;
  }
};

const getBenefitDetailsObjectByOrderType = ({
  orderType,
  benefitDetails,
}: FieldValues) => {
  if (orderType !== orderTypeConstants.GFE) {
    return null;
  }
  return benefitDetails;
};

const getPatientBenefitsByOrderType = ({
  orderType,
  deductible,
  outOfPocketMax,
  flatCopay,
  coinsurancePercent,
}: FieldValues) => {
  if (orderType !== orderTypeConstants.GFE) {
    return null;
  }
  const deductibleInCents = dollarsToCents(deductible || 0);
  const outOfPocketMaxInCents = dollarsToCents(outOfPocketMax || 0);
  const flatCopayInCents = dollarsToCents(flatCopay || 0);
  return {
    deductible: deductibleInCents,
    outOfPocketMax: outOfPocketMaxInCents,
    flatCopay: flatCopayInCents,
    coinsurancePercent,
  };
};

export const parseDateOfService = ({
  dateOfService,
  timeOfService,
}: FieldValues) => {
  const timeOfServiceParsed = timeOfService?.toLowerCase();

  const date = dayjs.utc(dateOfService).toDate();

  if (timeOfService && timeOfService !== "-") {
    const time = dayjs(timeOfServiceParsed, "hh:mm a").toDate();

    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    date.setSeconds(time.getSeconds());
    date.setMilliseconds(time.getMilliseconds());

    return dayjs(date).utc(true).format();
  }

  return dayjs(date).utc(true).format();
};

export const getOrderStatus = (props: any) => {
  const { orderType } = props;
  if (
    [
      orderTypeConstants.GFE,
      orderTypeConstants.BUNDLED,
      orderTypeConstants.ED,
      orderTypeConstants.BARIATRICS,
    ].includes(orderType) &&
    props.totalAmount === 0
  ) {
    return orderStatusConstants.NO_BALANCE_DUE;
  }
  if (
    orderType === orderTypeConstants.PATIENT_RESPONSIBILITY &&
    props.amount === 0
  ) {
    return orderStatusConstants.NO_BALANCE_DUE;
  }
  return props.status || orderStatusConstants.SENT_TO_PATIENT;
};

export const mapFormToRequestBody = (
  props: FieldValues,
  saveAsDraft?: boolean
) => {
  return {
    dateOfService: parseDateOfService(props),
    accountNumber: props.accountNumber,
    serviceDetails: getServiceDetailsObjectByOrderType(props),
    amountInCents: toCents(props.totalAmount) || 0,
    status: saveAsDraft ? orderStatusConstants.DRAFT : getOrderStatus(props),
    orderType: props.orderType,
    orderAttemptNumber: props.orderAttemptNumber || 0,
    patient: {
      lastName: props.lastName,
      firstName: props.firstName,
      dateOfBirth: convertToUTC(props.dateOfBirth),
      email: props.email || undefined,
      phoneNumber: props.phoneNumber || undefined,
      preferredContactMethod: props.preferredContactMethod,
      patientBenefits: {
        isSubscriberPatient: props.isSubscriberPatient,
        memberID: props.memberId,
      },
    },
    accountId: props.accountId,
    gfeQuoteId: props.gfeQuoteId,
    orderId: props.orderId || undefined,
    paymentPlanDuration: props.paymentPlanDuration,
    paymentPlanMinDownPaymentPercent: props.paymentPlanMinDownPaymentPercent,
    paymentPlanEnabled: props.paymentPlanEnabled,
    paymentPlanMinDownPaymentFlat:
      dollarsToCents(props?.paymentPlanMinDownPaymentFlat) || null,
    providerDistributionLogic:
      props.orderType === orderTypeConstants.PATIENT_RESPONSIBILITY
        ? null
        : props.providerDistributionLogic,
    customText: props.sendDefaultText ? null : props.customText,
    benefitDetails: getBenefitDetailsObjectByOrderType({
      orderType: props.orderType,
      benefitDetails: props.benefitDetails,
    }),
    outreachStopped: !props.outreachEnabled,
    patientBenefits: getPatientBenefitsByOrderType(props),
  } as IOrderCreateRequest;
};

export const buildURLforProviderProcedure = ({
  baseUrl,
  payerNameField,
  accountId,
  placeOfServiceField,
  localityField,
  cptCodeField,
  cptDescription,
  providerName,
  specialty,
  connectedHospital,
  page,
  pageSize,
}: {
  baseUrl: string;
  payerNameField?: string;
  accountId?: string;
  placeOfServiceField?: string;
  localityField?: string;
  cptCodeField?: string;
  cptDescription?: string;
  providerName?: string;
  specialty?: string;
  connectedHospital?: string;
  page?: number;
  pageSize?: number;
}): string => {
  const params = [
    !isEmpty(payerNameField) ? `payerName=${payerNameField}` : null,
    !isEmpty(placeOfServiceField)
      ? `placeOfService=${placeOfServiceField}`
      : null,
    !isEmpty(localityField) ? `locality=${localityField}` : null,
    !isEmpty(cptCodeField) ? `cptCode=${cptCodeField}` : null,
    !isEmpty(cptDescription) ? `cptDescription=${cptDescription}` : null,
    !isEmpty(providerName) ? `providerName=${providerName}` : null,
    !isEmpty(specialty) ? `specialty=${specialty}` : null,
    !isEmpty(connectedHospital)
      ? `connectedHospital=${connectedHospital}`
      : null,
  ].filter(Boolean);

  if (accountId === undefined) {
    if (page === undefined || pageSize === undefined) {
      return `${baseUrl}?page=0&pageSize=25${
        !isEmpty(params) ? "&" : ""
      }${params.join("&")}`;
    }
    return `${baseUrl}?page=${page}&pageSize=${pageSize}${
      !isEmpty(params) ? "&" : ""
    }${params.join("&")}`;
  }
  return `${baseUrl}?accountId=${accountId}${
    !isEmpty(params) ? "&" : ""
  }${params.join("&")}`;
};

const orderTypeMap: Record<OrderType, string> = {
  GFE: "gfe",
  Bundled: "bundled",
  ED: "ed",
  PatientResponsibility: "patientResponsibility",
  Bariatrics: "bariatrics",
};

export function getPaymentPlanEnabled(
  orderType: OrderType,
  paymentPlanTypeEnabled: IPaymentPlanTypeEnabled,
  order?: IOrder
): boolean {
  const key = orderTypeMap[orderType] as keyof IPaymentPlanTypeEnabled;
  const isEnabled = paymentPlanTypeEnabled[key];

  if (!isEmpty(order)) {
    switch (order.account.paymentPlanAvailability) {
      case availabilityList.SAME_DAY_DATE_CREATION: {
        const dateOfCreationIsSameOfToday = dayjs
          .utc()
          .isSameOrAfter(order.createdAt, "day");
        return isEnabled && dateOfCreationIsSameOfToday;
      }
      case availabilityList.THREE_DAY_BEFORE_DATE_OF_SERVICE: {
        const today = dayjs().utc().format("YYYY-MM-DD");
        const threeDayBeforeDateOfService = dayjs
          .utc(order.dateOfService)
          .subtract(3, "days")
          .isSameOrBefore(today, "day");
        return isEnabled && threeDayBeforeDateOfService;
      }
      case availabilityList.SAME_DAY_DATE_OF_SERVICE: {
        const today = dayjs().utc().format("YYYY-MM-DD");
        const sameDayOfService = dayjs
          .utc(order.dateOfService)
          .isSameOrBefore(today, "day");
        return isEnabled && sameDayOfService;
      }
      default: {
        return isEnabled;
      }
    }
  }
  return isEnabled;
}

export function getAccountLevelPaymentPlanAvailabilityEnabled(
  order: IOrder
): boolean {
  switch (order.account.paymentPlanAvailability) {
    case availabilityList.SAME_DAY_DATE_CREATION: {
      const dateOfCreationIsSameOfToday = dayjs
        .utc()
        .isSameOrAfter(order.createdAt, "day");
      return dateOfCreationIsSameOfToday;
    }
    case availabilityList.THREE_DAY_BEFORE_DATE_OF_SERVICE: {
      const today = dayjs().utc().format("YYYY-MM-DD");
      const threeDayBeforeDateOfService = dayjs
        .utc(order.dateOfService)
        .subtract(3, "days")
        .isSameOrBefore(today, "day");
      return threeDayBeforeDateOfService;
    }
    case availabilityList.SAME_DAY_DATE_OF_SERVICE: {
      const today = dayjs().utc().format("YYYY-MM-DD");
      const sameDayOfService = dayjs
        .utc(order.dateOfService)
        .isSameOrBefore(today, "day");
      return sameDayOfService;
    }
    default: {
      return true;
    }
  }
}
