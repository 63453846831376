import { VerificationSteps } from "pages/VerificationPage/utils/constants";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { onVerificationStepChange } from "store/slices/verification";

import { StyledSuccessContainer } from "../styled";
import { BouncingCheck } from "./BouncingCheck";

export function PaymentSuccessFull({
  publicContact,
  orderExternalId,
  receiptAvailable = true,
}: {
  publicContact: any;
  orderExternalId: string;
  receiptAvailable?: boolean;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <StyledSuccessContainer className="w-full flex flex-column align-items-center pt-6 px-3">
      <div className="flex relative">
        <BouncingCheck />
        <p className="text-md gray line-height-3 message">Great Job!</p>
      </div>
      <p className="text-4xl font-semibold highlighted mt-1 text-center">
        Your Payment is Complete!
      </p>
      <p className="text-center line-height-3 mt-4">
        Thank you for your payment—we&apos;ve got everything processed and ready
        for your next visit.
      </p>

      {receiptAvailable ? (
        <>
          <p className="text-center line-height-3 mt-4">
            A copy of your receipt has been sent to your email.
          </p>
          <Button
            className="p-button p-button-outlined mt-5"
            onClick={() => {
              navigate(`/receipt/${orderExternalId}`);
              dispatch(
                onVerificationStepChange({ step: VerificationSteps.RECEIPT })
              );
            }}
          >
            View Receipt
          </Button>
        </>
      ) : (
        <p className="text-center line-height-3 mt-4 font-semibold">
          We&apos;re preparing your receipt and will email it to you shortly.
          Our team is working to ensure your records are complete.
        </p>
      )}

      <Divider type="solid" className="mt-5" />
      <p className="highlighted font-medium text-xl line-height-3">
        Questions about your bill?
      </p>
      <p className="line-height-3 mt-1 text-center">
        Reach out to our support team anytime.
      </p>
      <div className="flex justify-content-center gap-4 mt-4">
        {publicContact().isVisible && (
          <div className="text-center flex flex-column">
            <p className="text-sm line-height-3 font-semibold">
              {publicContact().name}
            </p>
            <p className="text-sm line-height-3 gray">{publicContact().role}</p>
            <p className="text-sm line-height-3 gray">
              {publicContact().phone}
            </p>
          </div>
        )}

        <div className="text-center 1 flex flex-column">
          <p className="text-sm line-height-3 font-semibold">Brooke Leblanc</p>
          <p className="text-sm line-height-3 gray">Clear Health Support</p>
          <p className="text-sm line-height-3 gray">888-918-2522</p>
        </div>
      </div>
    </StyledSuccessContainer>
  );
}
