import { ItemBar } from "components/ItemBar";
import { useUserWithAccount } from "context/UserAccountProvider";
import { useQuery } from "hooks/useQuery";
import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { useMemo } from "react";
import styled from "styled-components";

const StyledH2 = styled.h2`
  margin-bottom: ${sizer(8)};
`;

export const StyledButton = styled(Button)`
  gap: ${sizer(2)};
  margin-bottom: -30px;

  span:first-child {
    font-size: ${sizer(3)};
  }
`;

export function DraftHeader() {
  const query = useQuery();
  const accountId = query.get("accountId") || undefined;
  const { accounts } = useUserWithAccount();

  const accountName = useMemo(() => {
    if (!accounts || !accountId) return "";
    const account = accounts.data.find((acc) => acc.id === accountId);
    return account?.name || "";
  }, [accounts]);

  return (
    <ItemBar>
      <StyledH2>{accountName}</StyledH2>

      <div data-testid="header" className="flex flex-1 justify-content-end" />
    </ItemBar>
  );
}
