import { LoadingSpinner } from "components/LoadingSpinner";
import dayjs from "dayjs";
import { Label } from "layout/typography/Label";
import { Calendar, CalendarValueType } from "primereact/calendar";
import { useState } from "react";
import { useGetOrdersQuery } from "store/queries/order";

import { Revenue } from "./components/Revenue";
import { SalesValues } from "./components/SalesValues";
import { SalesVolume } from "./components/SalesVolume";
import { HorizontalLine } from "./components/styled";
import { TicketCharts } from "./components/TicketCharts/TicketCharts";

export function Dashboard({ accountId }: { accountId?: string }) {
  const startOfMonth = dayjs().startOf("month").toDate();
  const today = dayjs().endOf("day").toDate();
  const [date, setDate] = useState<Date[]>([startOfMonth, today]);
  const { data: orders, isLoading } = useGetOrdersQuery({ accountId });

  function handleOnChangeFilter({
    newValue,
  }: {
    newValue: CalendarValueType | string;
  }) {
    setDate(newValue as Date[]);
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="formgrid grid col-2 gap-2 flex-wrap w-full">
      <div
        className="flex flex-column gap-2 w-fit"
        style={{ minWidth: "250px" }}
      >
        <Label htmlFor="from">Order Created Date</Label>
        <Calendar
          data-testid="range_calendar"
          selectionMode="range"
          placeholder="Order Created Date"
          value={date}
          showButtonBar
          className="w-fit"
          onChange={(e) => {
            handleOnChangeFilter({
              newValue: e.target.value || [],
            });
          }}
        />
      </div>
      <div className="flex w-full flex-column">
        <HorizontalLine />
      </div>
      {orders?.data && <TicketCharts orders={orders.data} date={date} />}
      {orders?.data && <SalesVolume orders={orders.data} date={date} />}
      {orders?.data && <SalesValues orders={orders.data} date={date} />}
      {orders?.data && <Revenue accountId={accountId} />}
    </div>
  );
}
