import dayjs from "dayjs";
import { parseDate } from "helpers/date";
import { Label } from "layout/typography/Label";
import { Button } from "primereact/button";
import { Calendar, CalendarValueType } from "primereact/calendar";
import { useState, useCallback, CSSProperties } from "react";
import {
  useGetPaidOrdersReportQuery,
  useGetAllOrdersReportQuery,
} from "store/queries/dashboard";

import { HorizontalLine } from "./styled";

export function Revenue({ accountId }: { accountId?: string }) {
  const startOfMonth = dayjs().startOf("month").toDate();
  const today = dayjs().toDate();
  const [paidDate, setPaidDate] = useState<Date[]>([startOfMonth, today]);
  const [createdDate, setCreatedDate] = useState<Date[]>([startOfMonth, today]);

  const { data, isLoading } = useGetPaidOrdersReportQuery({
    accountId,
    paidDate: (paidDate || [])
      .filter((date) => date != null)
      .map((date) =>
        dayjs.utc(date.toISOString()).startOf("day").toISOString()
      ),
  });

  const { data: allOrdersData, isLoading: isLoadingAllOrders } =
    useGetAllOrdersReportQuery({
      accountId,
      createdDate: (createdDate || [])
        .filter((date) => date != null)
        .map((date) =>
          dayjs.utc(date.toISOString()).startOf("day").toISOString()
        ),
    });

  const handleOnChangePaidDateFilter = useCallback(
    (e: { value: CalendarValueType }) => {
      setPaidDate(e.value as Date[]);
    },
    []
  );

  const handleOnChangeCreatedDateFilter = useCallback(
    (e: { value: CalendarValueType }) => {
      setCreatedDate(e.value as Date[]);
    },
    []
  );

  const convertDataToCSV = (data: any): string => {
    if (!data || !data.data || !data.data.length) return "";
    const headers = Object.keys(data.data[0]).join(",");
    const rows = data.data.map((row: any) => Object.values(row).join(","));
    return [headers, ...rows].join("\n");
  };

  const downloadPaidOrdersCSV = () => {
    if (!data || !data.data || !data.data.length) {
      alert("No data available to download.");
      return;
    }
    const changedData = data.data.map((item: any) => {
      return {
        ...item,
        order_create_date: parseDate(item.order_create_date, false, true),
        paid_date: parseDate(item.paid_date, false, true),
        service_date: parseDate(item.service_date, false, false),
      };
    });
    const csvString = convertDataToCSV({ data: changedData });
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `paid_orders_report_${new Date().getTime()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const downloadAllOrdersCSV = () => {
    if (!allOrdersData || !allOrdersData.data || !allOrdersData.data.length) {
      alert("No data available to download.");
      return;
    }
    const changedData = allOrdersData.data.map((item: any) => {
      return {
        ...item,
        order_create_date: parseDate(item.order_create_date, false, true),
        paid_date: parseDate(item.paid_date, false, true),
        service_date: parseDate(item.service_date, false, false),
      };
    });
    const csvString = convertDataToCSV({ data: changedData });
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `all_orders_report_${new Date().getTime()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const containerStyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "end",
    flexWrap: "wrap", // Ensures wrapping on smaller screens
  };

  return (
    <div className="flex flex-column gap-2 w-full">
      <HorizontalLine />
      <div style={containerStyle}>
        <div className="flex gap-2 align-items-end">
          <div
            className="flex flex-column gap-2 w-fit"
            style={{ minWidth: "250px" }}
          >
            <Label htmlFor="from">Order Paid Date</Label>
            <Calendar
              data-testid="range_calendar"
              selectionMode="range"
              placeholder="Order Paid Date"
              value={paidDate}
              onChange={handleOnChangePaidDateFilter}
            />
          </div>
          <Button
            label="Download"
            type="button"
            onClick={downloadPaidOrdersCSV}
            className="h-fit"
            icon="pi pi-download"
            disabled={isLoading}
          />
        </div>
        <div className="flex gap-2 align-items-end">
          <div
            className="flex flex-column gap-2 w-fit"
            style={{ minWidth: "250px" }}
          >
            <Label htmlFor="from">Order Created Date</Label>
            <Calendar
              data-testid="range_calendar_created"
              selectionMode="range"
              placeholder="Order Created Date"
              value={createdDate}
              onChange={handleOnChangeCreatedDateFilter}
            />
          </div>
          <Button
            label="Download"
            type="button"
            onClick={downloadAllOrdersCSV}
            className="h-fit"
            icon="pi pi-download"
            disabled={isLoadingAllOrders}
          />
        </div>
      </div>
    </div>
  );
}
