import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const CopyOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export function CopyOrderDialog({
  showCopyOrderDialog,
  setShowCopyOrderDialog,
  setOrderToCopy,
  orderId,
  accountId,
}: {
  showCopyOrderDialog: boolean;
  setShowCopyOrderDialog: any;
  setOrderToCopy: any;
  orderId: string;
  accountId?: string;
}) {
  const navigate = useNavigate();
  const footerComponent = () => {
    return (
      <div>
        <Button
          label="No"
          onClick={() => {
            setShowCopyOrderDialog(false);
            setOrderToCopy("");
          }}
          className="p-button-link mr-2"
        />
        <Button
          label="Yes"
          onClick={() => {
            navigate(`/orders/copy/${orderId}?accountId=${accountId}`);
            setShowCopyOrderDialog(false);
            setOrderToCopy("");
          }}
        />
      </div>
    );
  };
  return (
    <Dialog
      onHide={() => {
        setShowCopyOrderDialog(false);
        setOrderToCopy("");
      }}
      visible={showCopyOrderDialog}
      header="Confirmation"
      footer={footerComponent}
      closable
    >
      <div className="flex gap-2">
        <CopyOrderContainer>
          <span>Would you like to copy this order to start a new one?</span>
        </CopyOrderContainer>
      </div>
    </Dialog>
  );
}
