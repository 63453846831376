import { convertToPercent } from "../helpers";

export function BenefitLine({
  title,
  note,
  value,
  isCoinsurance,
  benefitEntities,
}: {
  title: string;
  note?: string | null;
  value?: string | null;
  isCoinsurance?: boolean;
  benefitEntities?: any;
}) {
  return (
    <div className="w-full flex justify-content-between">
      <div className="flex flex-column gap-1">
        <p className="text-sm">{title}</p>
        {note ? <p className="text-sm text-gray-600 pl-3">{note}</p> : null}
        {isCoinsurance && benefitEntities?.length
          ? benefitEntities.map((benefit: any, index: number) => (
              <p
                className="text-sm text-gray-600 pl-3"
                key={`${index}_benefit`}
              >
                {benefit?.entityType || "N/A"} - {benefit?.name || "N/A"}
              </p>
              // eslint-disable-next-line indent
            ))
          : null}
      </div>
      <p className="text-primary-500 font-semibold">
        {value ? convertToPercent(value) : "N/A"}
      </p>
    </div>
  );
}
