import { parsePrice } from "helpers/price";
import { Label } from "layout/typography/Label";
import { HorizontalLine } from "pages/Dashboard/components/styled";

import { BenefitLine } from "./BenefitLine";

export function ValueNoteSection({
  title,
  items,
  className,
}: {
  title: string;
  items: {
    Notes?: string | null;
    Value?: string | null;
    subtitle?: string | null;
    additionalCopay?: any | null;
    additionalCoinsurance?: any | null;
  }[];
  className?: string;
}) {
  if (!items.length) {
    return null;
  }
  return (
    <div className="flex flex-column gap-2">
      {items.map((item, index) => (
        <div
          className="flex flex-column gap-2"
          key={`${title}_${item.subtitle}_${index}`}
        >
          <BenefitLine
            title={`${title} ${item.subtitle || ""}`}
            note={item.Notes}
            value={item.Value}
          />
          <HorizontalLine marginTop="5px" marginBottom="5px" />
          {item?.additionalCopay?.map((copay: any, index: number) => (
            <>
              <BenefitLine
                key={`${index}_additionalCopay`}
                title={`${title} Additional Copay`}
                note={copay?.messages?.join("\n") || "N/A"}
                value={
                  copay.monetaryAmount
                    ? parsePrice(Number(copay.monetaryAmount))
                    : "N/A"
                }
              />
              <HorizontalLine marginTop="5px" marginBottom="5px" />
            </>
          ))}
          {item?.additionalCoinsurance?.map(
            (coinsurance: any, index: number) => (
              <>
                <BenefitLine
                  key={`${index}_additionalCoinsurance`}
                  title={`${title} Additional Coinsurance`}
                  value={coinsurance.percent ? `${coinsurance.percent}` : "N/A"}
                  isCoinsurance
                  benefitEntities={coinsurance?.benefitEntities}
                />
                <HorizontalLine marginTop="5px" marginBottom="5px" />
              </>
            )
          )}
        </div>
      ))}
    </div>
  );
}
