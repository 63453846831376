import { PropsWithChildren } from "react";

interface IPropsWithStyle extends PropsWithChildren {
  style?: { [key: string]: string };
}

export function ItemBar({ children, style }: IPropsWithStyle): JSX.Element {
  return (
    <div
      style={style || {}}
      className="flex flex-row align-items-center gap-2 justify-content-between pt-2 pb-2 w-full"
      id="test"
    >
      {children}
    </div>
  );
}

export function ItemBarLeft({ children, style }: IPropsWithStyle): JSX.Element {
  return (
    <div
      className="flex flex-row gap-2 align-items-center justify-content-start flex-wrap"
      style={style || {}}
    >
      {children}
    </div>
  );
}

export function ItemBarRight({ children }: PropsWithChildren): JSX.Element {
  return (
    <div className="flex flex-row gap-2 align-items-center justify-content-start flex-wrap">
      {children}
    </div>
  );
}
