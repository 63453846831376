import { motion } from "framer-motion";

export function BouncingCheck() {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "200px",
        height: "200px",
      }}
    >
      {/* Outer Circles */}
      <motion.div
        style={{
          position: "absolute",
          width: "70px",
          height: "70px",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          zIndex: 1,
          alignItems: "center",
          background:
            "linear-gradient(to bottom, #EDF1FD 0%, rgba(237, 241, 253, 0) 100%)",
        }}
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 2, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.1 }}
      >
        <div
          style={{
            position: "absolute",
            width: "calc(100% - 1.5px)",
            height: "calc(100% - 1.5px)",
            borderRadius: "50%",
            background: "white",
          }}
        />
      </motion.div>
      <motion.div
        style={{
          position: "absolute",
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background:
            "linear-gradient(to bottom, #EDF1FD 0%, rgba(237, 241, 253, 0) 100%)",
        }}
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 2, opacity: 1 }}
        transition={{ duration: 1, delay: 0.1 }}
      >
        <div
          style={{
            position: "absolute",
            width: "calc(100% - 1.5px)",
            height: "calc(100% - 1.5px)",
            borderRadius: "50%",
            background: "white",
          }}
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 1, scale: 0.5 }}
        animate={{
          opacity: 1,
          scale: [1, 1.4, 0.8, 1.1, 1], // Bubble effect
        }}
        transition={{
          duration: 1, // Full bounce effect
          times: [0, 0.2, 0.4, 0.6, 1],
        }}
        style={{
          position: "relative",
          width: "80px",
          height: "80px",
          borderRadius: "50%",
          background:
            "linear-gradient(to bottom, #EDF1FD 0%, rgba(237, 241, 253, 0) 100%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 2,
        }}
      >
        {/* Bouncing Tick Icon */}
        <motion.div
          initial={{ scale: 0.9 }}
          animate={{ scale: [1, 1.7, 0.9, 1.5] }} // Subtle bounce effect
          transition={{
            duration: 1,
            times: [0, 0.2, 0.4, 1],
            delay: 0.4,
          }}
          style={{ color: "white" }}
        >
          <i className="pi pi-check purple text-xl font-bold" />
        </motion.div>
      </motion.div>
    </div>
  );
}
