import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { orderTypeConstants } from "constants/order";
import { events } from "constants/tagManager";
import { ToastContext } from "context/ToastContext";
import { useUserWithAccount } from "context/UserAccountProvider";
import dayjs from "dayjs";
import { isValidEmail } from "helpers/email";
import { centsToDollars, parsePrice } from "helpers/price";
import { useAnalytics } from "hooks/useAnalytics";
import useDocumentTitle from "hooks/useDocumentTitle";
import { Label } from "layout/typography/Label";
import { debounce } from "lodash";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useContext, useEffect, useMemo, useState } from "react";
import { useGetCustomsByOrderTypeAndAccountIdQuery } from "store/queries/customize";
import { useCreatePaymentMutation } from "store/queries/order";
import { useUpdatePatientMutation } from "store/queries/patient";
import { useAttachPaymentMethodMutation } from "store/queries/stripe";
import { IOrder } from "types/Order/Order";

import {
  StyledButton,
  ContainerForm,
  EmailContainer,
  StyledInfoContainer,
} from "./styled";

type PaymentSectionProps = {
  prices: {
    clearPrice: number;
    totalSaving: number;
    fullPrice: number;
  };
  order: IOrder;
  expirationDays: number;
  onReturnClick: () => void;
  showPaymentSection: {
    paymentPlan: boolean;
    paymentSection: boolean;
    paymentSuccess: boolean;
  };
  setShowPaymentSection: any;
  payRemainingPrice?: boolean;
};

export function PaymentSection({
  order,
  prices,
  onReturnClick,
  expirationDays,
  showPaymentSection,
  setShowPaymentSection,
  payRemainingPrice,
}: PaymentSectionProps) {
  useDocumentTitle("Payment Page");
  const { userInfo } = useUserWithAccount();

  const { pageViewEvents } = useAnalytics();

  const { data: contentData } = useGetCustomsByOrderTypeAndAccountIdQuery(
    { orderType: order.orderType, accountId: order.account.id },
    {
      skip: !order,
    }
  );

  const { overviewPage: overviewContent } = contentData?.data || {};

  const [updatePatient, { isError: isUpdatePatientError }] =
    useUpdatePatientMutation();

  const [createPayment] = useCreatePaymentMutation();

  const [attachPaymentMethodToCustomer, { isError: isAttachedPaymentError }] =
    useAttachPaymentMethodMutation();

  const { current: toastElement } = useContext(ToastContext);

  const [isProcessing, setIsProcessing] = useState(false);

  const [patientEmail, setPatientEmail] = useState(order.patient.email);
  const [emailValid, setEmailValid] = useState(false);
  const [cardInformationComplete, setCardInformationComplete] = useState(false);

  const elements = useElements();
  const stripe = useStripe();

  useEffect(() => {
    setEmailValid(isValidEmail(patientEmail));
  }, [patientEmail]);

  const activePaymentPlan = useMemo(() => {
    return order.paymentPlan.find((plan) => plan.active);
  }, [order]);

  const initialPayment = useMemo(() => {
    const orderTypeAmountMap = new Map<string, number>([
      [orderTypeConstants.BARIATRICS, prices.clearPrice],
      [orderTypeConstants.BUNDLED, prices.clearPrice],
      [orderTypeConstants.PATIENT_RESPONSIBILITY, prices.fullPrice],
      [orderTypeConstants.GFE, prices.fullPrice],
      [orderTypeConstants.ED, prices.clearPrice],
    ]);
    const estimatedCost = orderTypeAmountMap.get(order.orderType) || 0;

    const minDownPaymentPercent =
      (activePaymentPlan?.paymentPlanMinDownPaymentPercent ?? 0) || 0;
    const minDownPaymentFlat = activePaymentPlan?.paymentPlanMinDownPaymentFlat;

    const minDownPayment =
      minDownPaymentFlat || estimatedCost * minDownPaymentPercent;

    return minDownPayment;
  }, [prices, order, activePaymentPlan]);

  const payNowDisabled = useMemo(() => {
    return !cardInformationComplete || !emailValid;
  }, [cardInformationComplete, emailValid]);

  const paymentSectionHeaderProps = useMemo(() => {
    if (showPaymentSection.paymentSection || payRemainingPrice) {
      return {
        title: showPaymentSection.paymentSection
          ? "Pay Now Amount"
          : "Pay Remaining Amount",
        amount: prices.clearPrice,
      };
    }
    if (showPaymentSection.paymentPlan) {
      return {
        title: "Initial Payment",
        amount: centsToDollars(initialPayment),
      };
    }
    return null;
  }, [showPaymentSection, prices, payRemainingPrice, initialPayment]);

  const debouncedPageViewEvents = debounce((order, event: string) => {
    pageViewEvents(
      {
        accountName: order?.accountName,
        orderType: order?.orderType,
        communicationMethod: order?.communicationMethod,
      },
      event
    );
  }, 500);

  const finishPayment = async () => {
    if (stripe && elements) {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      if (error) {
        setIsProcessing(false);

        toastElement?.show({
          severity: "error",
          detail: error.message,
          summary: "Payment failed",
        });
      }

      pageViewEvents(
        {
          accountName: order.account.name,
          orderType: order.orderType,
          communicationMethod: order.patient.preferredContactMethod,
        },
        events.PURCHASE
      );

      if (paymentIntent && paymentIntent.status === "succeeded") {
        createPayment({
          orderId: order.id,
          amountInCents: paymentIntent.amount,
          stripeBalanceTransactionId: paymentIntent.id,
          paymentType: paymentIntent.payment_method_types[0],
          userId: userInfo?.id,
          paymentMethodId: paymentIntent?.payment_method as string,
          payRemainingPrice,
        })
          .unwrap()
          .then(() => {
            // In this case, both payment and DB record succeeded
            setShowPaymentSection({
              paymentPlan: false,
              paymentSection: false,
              paymentSuccess: true,
              receiptAvailable: true,
            });
          })
          .catch((error) => {
            console.error(
              "Payment record creation in database failed but payment was successful on Stripe:",
              error
            );
            // Payment succeeded but database record failed
            // For this case, we will show the success UI but without the receipt
            setShowPaymentSection({
              paymentPlan: false,
              paymentSection: false,
              paymentSuccess: true,
              receiptAvailable: false,
            });
          });
      }
    }
  };

  const finishInitialPayment = async () => {
    if (stripe && elements) {
      // CONFIRM PAYMENT FOR INITIAL PAYMENT
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: `${order.patient.firstName} ${order.patient.lastName}`,
              email: patientEmail,
            },
          },
        },
      });

      if (error) {
        setIsProcessing(false);

        toastElement?.show({
          severity: "error",
          detail: error.message,
          summary: "Payment failed",
        });
      }

      pageViewEvents(
        {
          accountName: order.account.name,
          orderType: order.orderType,
          communicationMethod: order.patient.preferredContactMethod,
        },
        events.PURCHASE
      );

      if (paymentIntent && paymentIntent.status === "succeeded") {
        // We will try to attach payment method and still show error if it fails

        await attachPaymentMethodToCustomer({
          paymentMethodId: paymentIntent?.payment_method,
          customerId: order.patient.email,
        })
          .unwrap()
          .then(() => {
            console.log("Successfully attached payment method");
          })
          .catch((attachError) => {
            console.error("Error attaching payment method:", attachError);
            toastElement?.show({
              severity: "warn",
              summary: "Payment Method Attachment Failed",
              detail:
                "We encountered a challenge while trying to save your payment method. This may affect future automatic payments. Please contact support for assistance.",
            });
          });

        // We create the payment record
        createPayment({
          orderId: order.id,
          amountInCents: paymentIntent.amount,
          stripeBalanceTransactionId: paymentIntent.id,
          paymentType: paymentIntent.payment_method_types[0],
          userId: userInfo?.id,
          paymentMethodId: paymentIntent?.payment_method as string,
          paymentPlan: true,
        })
          .unwrap()
          .then(() => {
            // In this case, both payment and DB record succeeded
            setShowPaymentSection({
              paymentPlan: false,
              paymentSection: false,
              paymentSuccess: true,
              receiptAvailable: true,
            });
          })
          .catch((error) => {
            console.error(
              "Payment record creation in database failed but payment was successful on Stripe:",
              error
            );
            // Payment succeeded but database record failed
            // For this case, we will show the success UI but without the receipt
            setShowPaymentSection({
              paymentPlan: false,
              paymentSection: false,
              paymentSuccess: true,
              receiptAvailable: false,
            });
          });
      }
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setIsProcessing(true);

    debouncedPageViewEvents(
      {
        accountName: order.account.name,
        orderType: order.orderType,
        communicationMethod: order.patient.preferredContactMethod,
      },
      events.CLICK_PURCHASE
    );
    const emailIsChanged = patientEmail !== order.patient.email;
    const orderId = emailIsChanged ? order.id : undefined;
    const userId = userInfo?.id;
    updatePatient({
      id: order.patient.id,
      data: { email: patientEmail },
      orderId,
      userId,
    })
      .unwrap()
      .then(async () => {
        await finishPayment();
      })
      .catch(() => {
        if (isUpdatePatientError) {
          toastElement?.show({
            summary: "Error",
            severity: "error",
            detail: "Your payment cannot be completed. Please try again.",
          });
        }

        setIsProcessing(false);
      });
  };

  const handleSubmitPaymentPlan = async (e: any) => {
    e.preventDefault();

    setIsProcessing(true);

    debouncedPageViewEvents(
      {
        accountName: order.account.name,
        orderType: order.orderType,
        communicationMethod: order.patient.preferredContactMethod,
      },
      events.CLICK_PURCHASE
    );
    const emailIsChanged = patientEmail !== order.patient.email;
    const orderId = emailIsChanged ? order.id : undefined;
    const userId = userInfo?.id;
    updatePatient({
      id: order.patient.id,
      data: { email: patientEmail },
      orderId,
      userId,
    })
      .unwrap()
      .then(async () => {
        await finishInitialPayment();
      })
      .catch(() => {
        if (isUpdatePatientError) {
          toastElement?.show({
            summary: "Error",
            severity: "error",
            detail: "Your payment cannot be completed. Please try again.",
          });
        }
        setIsProcessing(false);
      });
  };

  const handleTakeToPrivacyPolicy = (e: any) => {
    e.preventDefault();
    window.open("https://clearhealthinc.com/privacy");
  };

  return (
    <ContainerForm>
      {paymentSectionHeaderProps && (
        <span className="title">
          <span>{paymentSectionHeaderProps.title}</span>
          <span>{parsePrice(paymentSectionHeaderProps.amount)}</span>
        </span>
      )}

      {overviewContent?.discountExpires && (
        <span className="mt-1 font-semibold text-base line-height-3 text-center warning">
          Discount expires on{" "}
          {dayjs
            .utc(order.dateOfService)
            .add(expirationDays, "day")
            .format("MMMM D, YYYY")}
        </span>
      )}

      <EmailContainer className="flex flex-column mt-5">
        <Label htmlFor="email">Email</Label>
        <div className="relative w-full">
          <InputText
            id="email"
            autoComplete="off"
            data-testid="email-input"
            value={patientEmail || ""}
            className="w-full"
            placeholder="example@email.com"
            onChange={(event) => setPatientEmail(event.target.value)}
          />
          <i className="pi pi-envelope absolute icon" />
        </div>
        {!emailValid && (
          <p className="text-xs text-red-500 font-medium text-left mt-1">
            Email is invalid
          </p>
        )}
      </EmailContainer>

      {/* START STRIPE ELEMENTS FOR CARD PAYMENT */}
      <div className="sensitive">
        <PaymentElement
          onChange={(event) => setCardInformationComplete(event.complete)}
        />
      </div>
      {/* END STRIPE ELEMENTS FOR CARD PAYMENT */}
      <p className="text-sm gray mt-2 text-center font-normal">
        {`By clicking "Pay Now", I confirm that I have read and agree to the`}{" "}
        <Button
          className="p-button-text text-sm p-0 border-none"
          onClick={handleTakeToPrivacyPolicy}
        >
          Privacy Policy
        </Button>{" "}
        {!!showPaymentSection.paymentPlan &&
          `and authorize Clear Health to automatically charge the provided credit
        card for the specified amount in the Payment Plan for each billing
        period`}
      </p>
      <p className="text-sm gray mt-2 text-center font-normal mt-4">
        <i className="pi pi-lock mr-2 text-sm " />
        All transactions are securely protected through encryption
      </p>

      {!!showPaymentSection.paymentPlan && (
        <Button
          type="submit"
          data-testid="button-submit-payment-plan"
          className="w-full justify-content-center  mb-1 mt-2"
          loading={isProcessing}
          onClick={handleSubmitPaymentPlan}
          disabled={payNowDisabled}
        >
          <span className="font-bold">Pay Now</span>
        </Button>
      )}
      {!!showPaymentSection.paymentSection && (
        <StyledButton
          type="submit"
          loading={isProcessing}
          data-testid="button-submit-pay-now"
          className="w-full justify-content-center mt-2"
          onClick={handleSubmit}
          disabled={payNowDisabled}
        >
          <span>Pay Now</span>
        </StyledButton>
      )}

      {!!payRemainingPrice && (
        <Button
          type="submit"
          loading={isProcessing}
          data-testid="button-submit-pay-now"
          className="w-full justify-content-center mt-2"
          onClick={handleSubmit}
          disabled={payNowDisabled}
        >
          <span>Pay Now</span>
        </Button>
      )}

      {!payRemainingPrice && (
        <div className="flex align-items-center justify-content-center mt-3">
          <Button
            className="p-button-text mb-0 p-button-secondary p-0"
            onClick={onReturnClick}
          >
            <p className="text-sm purple font-semibold">Back to details</p>
          </Button>
        </div>
      )}
      <StyledInfoContainer className="flex w-full border-round-lg p-4 justify-content-center align-items-center text-sm gray mt-4 text-center font-normal">
        Thousands of patients have used Clear Health to reduce their medical
        bills and you can too.
      </StyledInfoContainer>
      <div className="relative">
        <img src="/logo_stripe.png" alt="stripe logo" />
      </div>
    </ContainerForm>
  );
}
