import { orderTypeConstants } from "constants/order";
import { parsePrice, centsToDollars, dollarsToCents } from "helpers/price";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useMemo } from "react";
import styled from "styled-components";
import { IOrder } from "types/Order/Order";

interface ITransfer {
  selectedPartyToTransferFrom: {
    name: string;
    id: string;
    totalAmount: number;
    type: string;
  } | null;
  selectedPartyToTransferTo: {
    name: string;
    id: string;
    totalAmount: number;
    type: string;
  } | null;
  selectedAmountToTransfer: number;
}
export const StyledButton = styled(Button)`
  gap: ${sizer(2)};
  margin-bottom: ${sizer(4)};

  span:first-child {
    font-size: ${sizer(3)};
  }
`;

export function SingleTransferForm({
  order,
  transfer,
  setTransfers,
  index,
  handleRemoveTransfer,
  showRemoveButton,
}: {
  order: IOrder;
  transfer: ITransfer;
  setTransfers: any;
  index: number;
  showRemoveButton: boolean;
  handleRemoveTransfer: (index: number) => void;
}) {
  const paymentBreakdownDetails = useMemo(() => {
    return order?.paymentFullBreakdown?.bundledProviderAmounts;
  }, [order]);

  const providerTotals = useMemo(() => {
    const totals: {
      [key: string]: {
        name: string;
        id: string;
        totalAmount: number;
        type: string;
      };
    } = {};
    paymentBreakdownDetails?.forEach((amount) => {
      if (!totals[amount.providerId]) {
        totals[amount.providerId] = {
          name: amount.providerName,
          id: amount.providerId,
          totalAmount: 0,
          type: "provider",
        };
      }
      totals[amount.providerId].totalAmount += amount.expectedTransferAmount;
    });
    return Object.values(totals);
  }, [paymentBreakdownDetails]);

  const transferFromOptions = useMemo(() => {
    const orderIsPt =
      order.orderType === orderTypeConstants.PATIENT_RESPONSIBILITY;
    const facilityInfo = {
      name: order.account.providers[0].name as string,
      id: order.account.providers[0].id as string,
      totalAmount: order.paymentFullBreakdown
        ?.patientRespFacilityAmountInCents as number,
      type: "provider",
    };
    const fromOptions = [
      ...providerTotals,
      { name: "Clear Health", id: null, totalAmount: null, type: "provider" },
    ];
    if (orderIsPt) {
      fromOptions.push(facilityInfo);
    }
    return fromOptions;
  }, [providerTotals, order]);

  const transferToOptions = useMemo(() => {
    return [
      ...providerTotals,
      {
        name: `${order.patient.firstName} ${order.patient.lastName}`,
        id: order.patientId,
        type: "patient",
      },
      { name: "Clear Health", id: null, type: "provider" },
    ];
  }, [order, providerTotals]);
  const itemTemplate = (item: any) => {
    return (
      <div>
        {item.name}{" "}
        {item.totalAmount ? parsePrice(centsToDollars(item.totalAmount)) : ""}
      </div>
    );
  };

  const valueTemplate = (option: any, props: any) => {
    return option ? (
      <div>
        {option.name}{" "}
        {option.totalAmount
          ? parsePrice(centsToDollars(option.totalAmount))
          : ""}
      </div>
    ) : (
      // eslint-disable-next-line react/prop-types
      <div>{props.placeholder}</div>
    );
  };

  const onTransferFromChange = (selectedParty: any) => {
    const isClearHealth = selectedParty.id === null;
    const totalAllowable = selectedParty?.totalAmount;
    const currentAmount = transfer.selectedAmountToTransfer;
    if (isClearHealth) {
      setTransfers((prevTransfers: any) => {
        const updatedTransfers = [...prevTransfers];
        updatedTransfers[index] = {
          ...updatedTransfers[index],
          selectedPartyToTransferFrom: selectedParty,
        };
        return updatedTransfers;
      });
      return;
    }
    const updatedAmount =
      currentAmount > totalAllowable ? totalAllowable : currentAmount;
    setTransfers((prevTransfers: any) => {
      const updatedTransfers = [...prevTransfers];
      updatedTransfers[index] = {
        ...updatedTransfers[index],
        selectedPartyToTransferFrom: selectedParty,
        selectedAmountToTransfer: updatedAmount,
      };
      return updatedTransfers;
    });
  };

  const onTransferToChange = (selectedParty: any) => {
    setTransfers((prevTransfers: any) => {
      const updatedTransfers = [...prevTransfers];
      updatedTransfers[index] = {
        ...updatedTransfers[index],
        selectedPartyToTransferTo: selectedParty,
      };
      return updatedTransfers;
    });
  };
  const onAmountChange = (amount: number | null) => {
    let newAmount = amount || 0;
    const isClearHealth = transfer?.selectedPartyToTransferFrom?.id === null;
    const totalAllowable = transfer?.selectedPartyToTransferFrom?.totalAmount;
    if (
      !isClearHealth &&
      amount &&
      totalAllowable &&
      dollarsToCents(amount) > totalAllowable
    ) {
      newAmount = centsToDollars(totalAllowable);
    }
    setTransfers((prevTransfers: any) => {
      const updatedTransfers = [...prevTransfers];
      updatedTransfers[index] = {
        ...updatedTransfers[index],
        selectedAmountToTransfer: dollarsToCents(newAmount),
      };
      return updatedTransfers;
    });
  };

  return (
    <div className="flex flex-column">
      <div className="flex justify-content-between">
        <p className="font-semibold mb-2">Transfer {index + 1}</p>
        {showRemoveButton ? (
          <StyledButton
            data-testid="remove-transfer"
            icon="pi pi-times-circle"
            className="p-button-text p-button-danger p-0 mb-0"
            style={{ alignSelf: "end" }}
            onClick={() => handleRemoveTransfer(index)}
          >
            <p className="small-text">Remove transfer</p>
          </StyledButton>
        ) : null}
      </div>
      <div className="flex flex-column">
        <Label htmlFor="from">From</Label>
        <Dropdown
          options={transferFromOptions}
          value={transfer.selectedPartyToTransferFrom}
          valueTemplate={valueTemplate}
          optionLabel="providerName"
          className="mt-2 mb-4"
          onChange={(e) => onTransferFromChange(e.value)}
          itemTemplate={itemTemplate}
          placeholder="Select party"
        />
        <Label htmlFor="to">To</Label>
        <Dropdown
          options={transferToOptions}
          value={transfer.selectedPartyToTransferTo}
          optionLabel="name"
          className="mt-2 mb-4"
          onChange={(e) => onTransferToChange(e.value)}
          placeholder="Select party"
        />
        <Label htmlFor="to">Amount</Label>
        <InputNumber
          value={centsToDollars(transfer.selectedAmountToTransfer)}
          max={
            transfer.selectedPartyToTransferFrom &&
            transfer.selectedPartyToTransferFrom.id
              ? centsToDollars(transfer.selectedPartyToTransferFrom.totalAmount)
              : undefined
          }
          onChange={(e) => onAmountChange(e.value)}
          disabled={!transfer?.selectedPartyToTransferFrom}
          min={0}
          currency="USD"
          locale="en-US"
          mode="currency"
          inputId="amountToTransfer"
          className="mt-2 mb-4"
        />
      </div>
    </div>
  );
}
