import * as Popover from "@radix-ui/react-popover";
import { CallIcon } from "assets/icons/Call";
import { SmsIcon } from "assets/icons/Sms";
import { useQuery } from "hooks/useQuery";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGetAccountByIdQuery } from "store/queries/account";
import { useGetOrderOverviewQuery } from "store/queries/order";
import styled from "styled-components";

import { Logo } from "../Logo";
import { UserDropdown } from "../UserDropdown";

const HeaderStyled = styled.header`
  z-index: 999;
  height: 80px;

  box-sizing: border-box;

  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-black-1);

  @media (max-width: 720px) {
    height: 62px;
  }
  @media (min-width: 1024px) {
    padding-left: 96px !important;
    padding-right: 96px !important;
  }
`;

const LinkButtonStyled = styled.div`
  color: #717885;
`;

const StyledPopoverContent = styled(Popover.Content)`
  z-index: 3500;

  color: var(--color-purple);

  border: 1px solid var(--color-black-1);
  background-color: var(--color-white);
`;

const StyledPopoverTrigger = styled(Popover.Trigger)`
  all: unset;
`;

const Placeholder = styled.div`
  width: 1px;
`;

type NavbarProps = {
  variant?: "unlogged";
};

export function Navbar({ variant }: NavbarProps): JSX.Element {
  const isUnloggedVersion = variant === "unlogged";

  const { id } = useParams();
  const query = useQuery();
  const accountId = query.get("accountId") || undefined;

  const { data: overviewData } = useGetOrderOverviewQuery(id || "", {
    skip: id?.length !== 5,
  });

  const { data: accountByIdData } = useGetAccountByIdQuery(accountId || "", {
    skip: !accountId,
  });

  const logoUrl = useMemo(() => {
    if (!accountByIdData && !overviewData) return "";

    return (
      accountByIdData?.data.accountLogoUrl || overviewData?.data.accountLogo
    );
  }, [overviewData, accountByIdData]);

  return (
    <HeaderStyled className="w-full	p-3 fixed flex justify-content-between align-items-center">
      {!isUnloggedVersion || !logoUrl ? (
        <Placeholder />
      ) : (
        <Logo accountLogo={logoUrl} variant={variant} />
      )}

      {!isUnloggedVersion ? (
        <UserDropdown />
      ) : (
        <Popover.Root>
          <StyledPopoverTrigger>
            <LinkButtonStyled className="cursor-pointer text-xs sm:text-base">
              Help
            </LinkButtonStyled>
          </StyledPopoverTrigger>

          <Popover.Portal>
            <StyledPopoverContent className="cursor-default gap-2 flex flex-column py-4	px-3 border-round-lg mt-3 mr-4">
              <div className="flex align-items-center gap-2">
                <CallIcon className="mt-1" />
                <p>(888) 918-2522</p>
              </div>

              <div className="flex align-items-center gap-2">
                <SmsIcon className="mt-1" />
                <p>support@clearhealthinc.com</p>
              </div>
            </StyledPopoverContent>
          </Popover.Portal>
        </Popover.Root>
      )}
    </HeaderStyled>
  );
}
