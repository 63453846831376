import { Insurance } from "assets/icons/Insurance";
import { UserRole } from "constants/userRole";
import { Tooltip } from "primereact/tooltip";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { userSelector } from "store/slices/user";
import styled from "styled-components";

import { CustomNavLink } from "./components/CustomNavLink/CustomNavLink";

const Container = styled.div`
  width: 100px;
  height: calc(100vh - 80px);

  top: var(--navbar-height);
  position: fixed;
  bottom: 0;

  background-color: var(--color-white);
  border-right: 1px solid var(--color-black-1);

  gap: 30px;

  display: flex;
  align-items: center;
  flex-direction: column;

  padding: var(--space-md) var(--space-sm);
`;

const Item = styled(NavLink)`
  all: unset;

  width: 50px;
  height: 50px;
  cursor: pointer;

  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--bg-white);

  :hover {
    background: var(--primary-10);
  }

  &.active {
    background: var(--primary-10);

    i {
      color: var(--color-purple);
    }
  }
`;

export function Sidebar(): JSX.Element {
  const { scope } = useSelector(userSelector);

  return (
    <Container className="flex justify-content-between">
      <div className="flex flex-column gap-3">
        {scope === UserRole.SUPER_ADMIN && (
          <>
            <Tooltip
              style={{
                fontSize: "14px",
              }}
              target=".item-tooltip"
            />

            <Item
              className="item-tooltip"
              data-pr-tooltip="Reports"
              data-pr-position="right"
              data-testid="sidebar-reports-link"
              to="/reports"
            >
              <i className="pi pi-chart-line" />
            </Item>

            <CustomNavLink
              to="/hospitals"
              data-testid="sidebar-hospitals-link"
              matchLinks={["/hospitals/*", "/orders/*"]}
            >
              <i className="pi pi-building" />
            </CustomNavLink>
            <Item
              to="/patients"
              className="item-tooltip"
              data-pr-tooltip="Patients"
              data-pr-position="right"
            >
              <i className="pi pi-id-card" />
            </Item>

            <Item
              className="item-tooltip"
              data-pr-tooltip="Fee Schedule"
              data-pr-position="right"
              to="/fee-schedule"
              data-testid="sidebar-fee-schedule-link"
            >
              <i className="pi pi-money-bill" />
            </Item>

            <Item
              to="/users"
              className="item-tooltip"
              data-pr-tooltip="Users"
              data-pr-position="right"
            >
              <i className="pi pi-users" />
            </Item>
            <Item
              to="/customize"
              className="item-tooltip"
              data-pr-tooltip="Customize"
              data-pr-position="right"
              data-test-id="sidebar-customize-link"
            >
              <i className="pi pi-cog" />
            </Item>
            <Item
              to="/payer-rules"
              className="item-tooltip"
              data-pr-tooltip="Payer Rules"
              data-pr-position="right"
              data-test-id="sidebar-payer-rules-link"
            >
              <Insurance width={16} height={16} />
            </Item>
          </>
        )}
      </div>

      <img
        src="/logo.svg"
        alt="Clear Health Logo"
        style={{ cursor: "pointer", width: "100%" }}
      />
    </Container>
  );
}
