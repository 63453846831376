import { orderTypeConstants } from "constants/order";
import dayjs from "dayjs";
import { parseDate, parseTime } from "helpers/date";
import { interpolateString } from "helpers/interpolate";
import { parsePrice, centsToDollars } from "helpers/price";
import { getAccountLevelPaymentPlanAvailabilityEnabled } from "pages/CreateOrder/utils/helpers";
import { disclaimerDefaultValues } from "pages/Customize/helper";
import { Button } from "primereact/button";
import { useMemo, useState } from "react";
import { SpecialtyList } from "types/Provider/Provider";

import {
  HorizontalLine,
  StyledSectionContainer,
  StyledSelectButton,
  StyledPriceContainer,
  StyledTag,
} from "../styled";
import DetailItem from "./DetailItem";
import DisclaimerDialog from "./DisclaimerDialog";
import PaymentPlan from "./PaymentPlan";
import { ServiceInfo } from "./ServiceInfo";

export function Home({
  content,
  orderInfo,
  procedures,
  isGFE,
  showClearEstimateDetails,
  showClearEstimateDetailsSection,
  setDisclaimerDialogVisible,
  facilityFee,
  physicianFee,
  clearPrice,
  fullPrice,
  percentageOff,
  totalSaving,
  totalAllowedInsuranceAmount,
  totalInsurancePayInCents,
  totalFlatCopayInCents,
  totalDeductibleInCents,
  totalCoinsuranceInCents,
  expirationDays,
  setShowPaymentSection,
  publicContact,
  disclaimerDialogVisible,
}: any) {
  const [showPaymentsSection, setShowPaymentsSection] = useState(false);
  const [payInFull, setPayInFull] = useState(true);
  const parseText = (text: string) => {
    return interpolateString(text, {
      actualSavings: parsePrice(totalSaving),
    });
  };

  const showPaymentPlanSection = useMemo(() => {
    const paymentPlanIsEnabledForOrder = !!orderInfo.paymentPlan.find(
      (p: any) => p.active
    );
    const paymentPlanIsEnabledForAccount =
      getAccountLevelPaymentPlanAvailabilityEnabled(orderInfo);
    return paymentPlanIsEnabledForOrder && paymentPlanIsEnabledForAccount;
  }, [orderInfo]);

  const isPatientResponsibility = useMemo(() => {
    return orderInfo.orderType === orderTypeConstants.PATIENT_RESPONSIBILITY;
  }, [orderInfo]);

  const fullProviderBreakdown = useMemo(() => {
    return orderInfo?.paymentFullBreakdown?.bundledProviderAmounts;
  }, [orderInfo]);

  const exparel = useMemo(() => {
    const exparelProceduresAmount = orderInfo.serviceDetails.procedures
      .filter((p: any) => p?.code === "Exparel")
      .reduce((acc: number, current: any) => {
        return acc + (current?.amountInCents || 0);
      }, 0);
    return exparelProceduresAmount || 0;
  }, [orderInfo]);

  const scd = useMemo(() => {
    const scdProceduresAmount = orderInfo.serviceDetails.procedures
      .filter((p: any) => p?.code === "SCD")
      .reduce((acc: number, current: any) => {
        return acc + (current?.amountInCents || 0);
      }, 0);
    return scdProceduresAmount || 0;
  }, [orderInfo]);

  const facilityFees = useMemo(() => {
    const sumOfFacilityFees = fullProviderBreakdown
      ?.filter((p: any) => p?.specialty === SpecialtyList.FACILITY)
      .reduce((acc: number, current: any) => {
        return acc + (current?.clearDiscountedPrice || 0);
      }, 0);
    return sumOfFacilityFees - scd - exparel;
  }, [fullProviderBreakdown, scd, exparel]);

  const surgeonFees = useMemo(() => {
    const sumOfSurgeonFees = fullProviderBreakdown
      ?.filter((p: any) => p?.specialty === SpecialtyList.PRIMARY_PROVIDER)
      .reduce((acc: number, current: any) => {
        return acc + (current?.clearDiscountedPrice || 0);
      }, 0);
    return sumOfSurgeonFees || 0;
  }, [fullProviderBreakdown]);

  const anesthesiaFees = useMemo(() => {
    const sumOfAnesthesiaFees = fullProviderBreakdown
      ?.filter((p: any) => p?.specialty === SpecialtyList.ANESTHESIOLOGIST)
      .reduce((acc: number, current: any) => {
        return acc + (current?.clearDiscountedPrice || 0);
      }, 0);
    return sumOfAnesthesiaFees || 0;
  }, [fullProviderBreakdown]);

  const clearEstimateDetailsInfo = useMemo(() => {
    if (!isGFE) return [];
    return [
      {
        title: "Total insurance allowable",
        value: parsePrice(centsToDollars(totalAllowedInsuranceAmount)),
      },
      {
        title: "Insurance will Pay",
        value: parsePrice(centsToDollars(totalInsurancePayInCents)),
      },
      {
        title: "Flat Copay Amount Applied",
        value: parsePrice(centsToDollars(totalFlatCopayInCents)),
      },
      {
        title: "Deductible Amount Applied",
        value: parsePrice(centsToDollars(totalDeductibleInCents)),
      },
      {
        title: "Co-insurance Amount Applied",
        value: parsePrice(centsToDollars(totalCoinsuranceInCents)),
      },
    ];
  }, [
    totalAllowedInsuranceAmount,
    totalInsurancePayInCents,
    totalFlatCopayInCents,
    totalDeductibleInCents,
    totalCoinsuranceInCents,
    isGFE,
  ]);

  const facilityFeesDetailsInfo = useMemo(() => {
    if (!isGFE) return [];
    const exparelValue = {
      title: "Exparel",
      value: parsePrice(centsToDollars(exparel)),
    };
    const scdValue = {
      title: "SCD",
      value: parsePrice(centsToDollars(scd)),
    };
    return [
      {
        title: "Surgeon fees",
        value: parsePrice(centsToDollars(surgeonFees)),
      },
      {
        title: "Anesthesia fees",
        value: parsePrice(centsToDollars(anesthesiaFees)),
      },
      {
        title: "Facility fees",
        value: parsePrice(centsToDollars(facilityFees)),
      },
      ...(exparel ? [exparelValue] : []),
      ...(scd ? [scdValue] : []),
    ];
  }, [surgeonFees, anesthesiaFees, facilityFees, exparel, scd]);

  const options = useMemo(() => {
    return ["Pay in Full", "Payment Plan"];
  }, []);

  const handleContinue = () => {
    if (payInFull) {
      setShowPaymentSection({
        paymentPlan: false,
        paymentSection: true,
        paymentSuccess: false,
      });
    } else {
      setShowPaymentSection({
        paymentPlan: true,
        paymentSection: false,
        paymentSuccess: false,
      });
    }
  };

  const justifyTemplate = (option: any) => {
    if (option === options[1]) {
      return <span className="p-button-label p-c">{option}</span>;
    }
    const discountPercentTotal =
      content?.discountPercentTotal === undefined ||
      content?.discountPercentTotal === true;
    return (
      <div
        className="p-button-label p-c flex gap-2 justify-content-center align-items-center"
        style={{
          marginBottom: disclaimerDefaultValues && "-2px",
          marginTop: disclaimerDefaultValues && "-2px",
        }}
      >
        {option}
        {discountPercentTotal && (
          <StyledTag severity="success" value={`Save ${percentageOff}%`} />
        )}
      </div>
    );
  };

  return (
    <div>
      <h2 className="text-base md:text-xl line-height-3 font-semibold mb-4 purple">
        {parseText(content?.headline)}
      </h2>
      <StyledSectionContainer className="px-3">
        <div className="flex flex-column w-full">
          <p className="line-height-2 font-medium text-xs">SERVICES PROVIDED</p>
          <p className="text-base line-height-3 font-medium highlighted mt-2">
            {parseDate(orderInfo.dateOfService)}{" "}
            {parseTime(orderInfo.dateOfService)}
          </p>
          <ServiceInfo
            title={`${procedures[0].cptDescription}`}
            providers={
              isPatientResponsibility
                ? [{ name: orderInfo.account.name }]
                : procedures[0].providers
            }
            quantity={procedures[0].providers[0]?.quantity}
            code={procedures[0].code}
          />
          {showPaymentsSection &&
            procedures
              .slice(1)
              .map((procedure: any) => (
                <ServiceInfo
                  title={`${procedure.cptDescription}`}
                  providers={
                    isPatientResponsibility
                      ? [{ name: orderInfo.account.name }]
                      : procedure.providers
                  }
                  key={`${procedure.code}-${procedure.providers[0]?.id}`}
                  quantity={procedure.providers[0]?.quantity}
                  code={procedure.code}
                />
              ))}
        </div>
        {procedures.length > 1 && (
          <Button
            label={showPaymentsSection ? "Hide services" : "View all services"}
            className="p-button-text p-0 w-fit text-sm"
            style={{ color: "#4f46e5" }}
            icon={`pi pi-chevron-up transition-duration-500 transition-all ${
              showPaymentsSection ? "" : "rotate-180"
            }`}
            iconPos="right"
            onClick={() => setShowPaymentsSection(!showPaymentsSection)}
          />
        )}
      </StyledSectionContainer>
      <StyledSectionContainer>
        <div
          className={`flex flex-column w-full gap-3 px-3  ${
            isGFE && showClearEstimateDetailsSection && "shadow pb-3"
          }`}
        >
          <div className="flex flex-column w-full">
            <p className="line-height-2 font-medium text-xs mb-2">
              ACCOUNT SUMMARY
            </p>
            <div className="flex justify-content-between gap-4 sm:gap-8">
              <div className="flex flex-column">
                <p className="text-base line-height-3 font-semibold highlighted mb-1">
                  {content?.estimateTitle?.trim().length
                    ? parseText(content.estimateTitle)
                    : "Estimated Payment Due"}
                </p>
                <p className="text-sm line-height-2 font-normal">
                  {parseText(content?.estimateDescription)}
                </p>
              </div>
              <div
                className="flex-initial flex-column mb-2 w-100"
                style={{
                  minWidth: "80px",
                  marginTop: content?.facilityFeeDescription?.length
                    ? "-15px"
                    : "",
                }}
              >
                {content?.facilityEstimate && (
                  <div className="flex flex-column">
                    <p className="text-xs gray text-right">
                      {parseText(content?.facilityFeeDescription)}
                    </p>
                    <p className="text-base line-height-3 font-semibold highlighted text-right">
                      {parsePrice(facilityFee)}
                    </p>
                  </div>
                )}

                {content?.physicianEstimate && (
                  <div className="flex flex-column mt-2">
                    <p className="text-xs gray text-right">Physician fee</p>
                    <p className="text-base line-height-3 font-semibold highlighted text-right">
                      {parsePrice(physicianFee)}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          {isGFE && (
            <Button
              label={
                showClearEstimateDetailsSection
                  ? "Hide Details"
                  : "Why do I owe this amount?"
              }
              className="p-button-text p-0 w-fit text-sm"
              style={{ color: "#4f46e5" }}
              icon={`pi pi-chevron-up transition-duration-500 transition-all ${
                showClearEstimateDetailsSection ? "" : "rotate-180"
              }`}
              iconPos="right"
              onClick={showClearEstimateDetails}
            />
          )}
        </div>
        {showClearEstimateDetailsSection && isGFE && (
          <div className="flex flex-column w-full gap-3 px-3">
            <div className="flex flex-column w-full gap-1">
              <div className="flex flex-column w-full gap-1">
                {facilityFeesDetailsInfo.map((info: any, index) => (
                  <DetailItem
                    title={info.title}
                    value={info.value}
                    key={`${info.title}_${index}`}
                  />
                ))}
                <HorizontalLine className="my-2" />
                {clearEstimateDetailsInfo.map((info: any, index) => (
                  <DetailItem
                    title={info.title}
                    value={info.value}
                    key={`${info.title}_${index}`}
                  />
                ))}
              </div>
              <Button
                className="p-button-text text-sm p-0 text-left"
                style={{ color: "#4f46e5" }}
                onClick={() => setDisclaimerDialogVisible(true)}
              >
                {content.disclaimerTitleEnglish ||
                  disclaimerDefaultValues.disclaimerTitleEnglish}
              </Button>
              <DisclaimerDialog
                visible={disclaimerDialogVisible}
                onClose={() => setDisclaimerDialogVisible(false)}
                englishTitle={
                  content.disclaimerTitleEnglish ||
                  disclaimerDefaultValues.disclaimerTitleEnglish
                }
                englishDescription={
                  content.disclaimerDescriptionEnglish ||
                  disclaimerDefaultValues.disclaimerDescriptionEnglish
                }
                spanishTitle={
                  content.disclaimerTitleSpanish ||
                  disclaimerDefaultValues.disclaimerTitleSpanish
                }
                spanishDescription={
                  content.disclaimerDescriptionSpanish ||
                  disclaimerDefaultValues.disclaimerDescriptionSpanish
                }
              />
            </div>
            <HorizontalLine />
            <p className="text-xs line-height-2 font-normal gray">
              Based on your insurance plan, you may be responsible for
              deductible and coinsurance payments until you reach your annual
              out-of-pocket maximum. After that, your insurance will cover 100%
              of the costs.
            </p>
          </div>
        )}
      </StyledSectionContainer>
      {showPaymentPlanSection && (
        <StyledSelectButton
          value={payInFull ? options[0] : options[1]}
          onChange={(e) =>
            e.value === options[0] ? setPayInFull(true) : setPayInFull(false)
          }
          options={options}
          itemTemplate={justifyTemplate}
        />
      )}
      {payInFull && (
        <>
          <StyledPriceContainer>
            <div className="flex flex-column gap-1">
              <h2 className="text-base font-semibold line-height-3">
                {parseText(content?.clearPriceTitle) || "Pay Now Price"}
              </h2>
              <p className="text-sm font-normal line-height-2">
                {parseText(content?.clearPriceDescription)}
              </p>
            </div>
            <div className="flex flex-column gap-1 line-height-4 font-semibold text-right">
              <p className="purple text-xl">{parsePrice(clearPrice)}</p>
              {(content?.discountPercentTotal === undefined ||
                content?.discountPercentTotal === true) && (
                <div className="flex flex-column gap-1">
                  <p className="text-xs green font-normal line-height-1">{`${percentageOff}% off`}</p>
                  <p className="text-xs font-normal line-height-1">
                    Total price:{" "}
                    <span style={{ textDecoration: "line-through" }}>
                      {parsePrice(fullPrice)}
                    </span>
                  </p>
                </div>
              )}
            </div>
          </StyledPriceContainer>
          {content?.discountExpires && (
            <p className="mt-2 text-center red text-base font-semibold line-height-3">
              Discount expires on{" "}
              {dayjs
                .utc(orderInfo.dateOfService)
                .add(expirationDays, "day")
                .format("MMMM D, YYYY")}
            </p>
          )}
        </>
      )}
      {!payInFull && showPaymentPlanSection && (
        <PaymentPlan
          orderInfo={orderInfo}
          fullPrice={fullPrice}
          clearPrice={clearPrice}
        />
      )}
      <Button
        className="w-full justify-content-center mt-6 border-round-lg"
        onClick={handleContinue}
      >
        Continue
      </Button>
      <div className="flex justify-content-center gap-4 mt-4">
        {publicContact().isVisible && (
          <div className="text-center gap-1 flex flex-column">
            <p className="text-sm line-height-3 font-semibold">
              {publicContact().name}
            </p>
            <p className="text-sm line-height-3 font-normal">
              {" "}
              {publicContact().role}
            </p>
            <p className="text-sm line-height-3 font-normal">
              {" "}
              {publicContact().phone}
            </p>
          </div>
        )}

        <div className="text-center gap-1 flex flex-column">
          <p className="text-sm line-height-3 font-semibold">Brooke Leblanc</p>
          <p className="text-sm line-height-3 font-normal">
            Clear Health Support
          </p>
          <p className="text-sm line-height-3 font-normal">888-918-2522</p>
        </div>
      </div>
    </div>
  );
}
