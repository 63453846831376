import { ToastContext } from "context/ToastContext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useContext, useState } from "react";
import { useCancelSingleOrderMutation } from "store/queries/order";
import styled from "styled-components";

const CancelOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export function CancelOrderDialog({
  showCancelDialog,
  setShowCancelDialog,
  cancelNotes,
  setCancelNotes,
  orderId,
  onUpdateOrder,
}: {
  showCancelDialog: boolean;
  setShowCancelDialog: any;
  cancelNotes: string;
  setCancelNotes: any;
  orderId: string;
  onUpdateOrder: any;
}) {
  const [cancelSingleOrder] = useCancelSingleOrderMutation();
  const { current: toastElement } = useContext(ToastContext);

  const [loading, setLoading] = useState(false);

  const cancelOrder = () => {
    setLoading(true);

    cancelSingleOrder({
      orderId,
      note: cancelNotes.trim()?.length ? cancelNotes.trim() : undefined,
    })
      .unwrap()
      .then(async () => {
        toastElement?.show({
          severity: "success",
          summary: "Order canceled",
          detail: "Order canceled successfully.",
        });
        onUpdateOrder();
      })
      .catch((error) => {
        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail: `Message: ${error.message}`,
        });
      })
      .finally(() => {
        setLoading(false);
        setShowCancelDialog(false);
      });
  };

  const footerComponent = () => {
    return (
      <div>
        <Button
          label="No"
          onClick={() => {
            setCancelNotes("");
            setShowCancelDialog(false);
          }}
          className="p-button-link mr-2"
          disabled={loading}
        />
        <Button label="Cancel" onClick={cancelOrder} loading={loading} />
      </div>
    );
  };
  return (
    <Dialog
      onHide={() => {
        setCancelNotes("");
        setShowCancelDialog(false);
      }}
      visible={showCancelDialog}
      header="Confirmation"
      footer={footerComponent}
      closable={false}
    >
      <div className="flex gap-2">
        <i
          className="pi pi-exclamation-triangle"
          style={{ fontSize: "28px" }}
        />
        <CancelOrderContainer>
          <span>Are you sure you want to cancel this order?</span>
          <InputTextarea
            value={cancelNotes}
            onChange={(e) => setCancelNotes(e.target.value)}
            rows={2}
            placeholder="Notes"
            className="w-full mt-3"
          />
        </CancelOrderContainer>
      </div>
    </Dialog>
  );
}
