import { PDFDownloadLink } from "@react-pdf/renderer";
import styled from "styled-components";

import { StyledButton } from "../OrderHeader";
import { ClearOrderDocument, Props } from "./ClearOrderDocument";

const StyledPDFDownloadLink = styled(PDFDownloadLink)`
  text-decoration: none;
  color: var(--primary-color);
`;

export function ClearOrderProDownloadButton({ data }: { data: Props }) {
  return (
    <StyledButton
      className="p-button-text p-button-primary p-0 mb-0"
      icon="pi pi-file"
    >
      <StyledPDFDownloadLink
        data-testid="download-pdf_OrderHeader_DownloadLink"
        document={<ClearOrderDocument {...data} />}
        fileName={`clear_order__${new Date().getTime()}.pdf`}
      >
        {({ loading }) => (loading ? "Loading..." : "Download PDF")}
      </StyledPDFDownloadLink>
    </StyledButton>
  );
}
