import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import styled from "styled-components";

export const ContainerForm = styled.form`
  max-width: 522px;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 30px;
    line-height: 36px;
    color: #4f46e5;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .warning {
    color: #f04438;
  }

  .gray {
    color: #858490;
  }

  p {
    text-align: center;
    margin-bottom: 8px;

    font-weight: 700;
    font-size: 24px;

    color: #5b4dfd;
  }
  img {
    height: 30px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 38px;
    @media (max-width: 720px) {
      top: 32px;
    }
  }
`;

export const EmailContainer = styled.div`
  margin-bottom: 24px;
  label {
    font-size: 14px;
    line-height: 20px;
    color: #64636e;
    font-weight: 500;
    margin-bottom: ${sizer(1)};
  }
  input {
    padding-left: 32px;
    border-radius: 8px;
    border: 1px solid #d2d6db;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .icon {
    display: flex;
    top: 50%;
    left: 12px;
    position: absolute;
    transform: translateY(-50%);
    color: #a5a4ad;
  }
`;

export const StyledButton = styled(Button)`
  background-color: "#5B4DFD" !important;
  font-weight: 600;
`;

export const StyledInfoContainer = styled.div`
  background-color: #f7f7f8;
`;

export const StyledSuccessContainer = styled.div`
  max-width: 640px;
  p {
    color: #706f7b;
  }
  .purple {
    color: #4f46e5;
  }
  .gray {
    color: #858490;
  }
  .message {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -2px;
  }

  .highlighted {
    color: #17143f;
  }

  .p-divider {
    height: 1px;
    background: #d3d2d5;
  }

  button {
    color: #4f46e5 !important;
  }
`;
